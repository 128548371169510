.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-links li {
  margin: 1rem;
  display: inline-flex;
  margin: 0 1rem;
}

.nav-links a {
  text-decoration: none;
  background: transparent;
  color: #9EA4BC;
  padding: 10px 20px;
  font-size: 1.2rem;
}

.nav-links a:hover,
.nav-links a:active,
.nav-links a.active {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.nav-links button {
  cursor: pointer;
  border: 1px solid #e7e7e7;
  color: white;
  background: black;
  padding: 6px 15px;
  font-size: 16px;
}

.nav-links button:focus {
  outline: none;
}

.nav-links button:hover,
.nav-links button:active {
  background: #292929;
  color: black;
}

.nav-links .ant-avatar-image{
  width: 32px;
  height: 30px;
  margin-top: -3px;
  border-radius: 0;
  margin-right: 6px;
  border: none;
  border-right: 1px solid #ddd;
}
.nav-links .avatar-link{
  padding-left: 0;
}

@media (min-width: 768px) {
  .nav-links {
    flex-direction: row;
  }

  .nav-links li {
    margin: 0 1rem;
  }

  .nav-links button {
    background: #f4f4f4;
    border-color: #e7e7e7;
    color: #030303;
    padding: 3px 15px;
    font-size: 16px;
  }
  
  .nav-links button:hover,
  .nav-links button:active {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d;
  }
}
