.player-scenario-wrapper{
    padding: 40px 0;
    border-radius: 0;
    border-bottom: 3px solid #e4e7ea;
}
.player-scenario-wrapper:last-of-type{
    border-bottom: none;
}
.scenario-item__wrapper{
    border: 2px solid #cdd0d1;
    border-radius: 0;
    margin-bottom: 15px;
    background: white;
    border-radius: 3px;
}
.scenario-item__media{
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;
    position: relative;
}
.scenario-item__media .img-wrapper{
    padding: 25px;
    width: 440px;
}
.scenario-item__media .img-wrapper img{
    width: 100%;
    border-radius: 8px;
}
.scenario-item__media .body{
    width: calc(100% - 340px);
    padding: 25px;
    padding-left: 10px;
    position: relative;
    min-height: 200px;
}
.scenario-item__media .headline{
    font-size: 18px;
}
.scenario-item__media .sub-headline{
    font-size: 16px;
}
.scenario-item__media .description{
    font-size: 14px;
    min-height: 75px;
}
.scenario-item__wrapper .btns{
    display: flex;
    justify-content: flex-end;
    padding: 0;
    flex-wrap: wrap;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    padding-right: 20px;
    padding-bottom: 20px;
}
.scenario-item__wrapper .btns button{
    margin-left: 10px;
    margin-bottom: 7px;
}
.scenario-item__wrapper .btns button:first-of-type{
    margin-left: 0px;
}
.top-plays-wrapper{
    padding-top: 37px;
}