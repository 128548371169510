.input-wrapper {
  margin: 1rem 0;
  border: none;
  height: auto;
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
}

.input-wrapper label,
.input-wrapper input,
.input-wrapper textarea,
.input-wrapper select {
  display: block;
  font-size: 0.9rem;
}

.input-wrapper label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.input-wrapper input,
.input-wrapper textarea,
.input-wrapper select {
  width: 100%;
  font: inherit;
  border: 1px solid #ccc;
  background: #f8f8f8;
  padding: 10px;
  border-radius: 4px;
  min-height: 46px;
}

.input-wrapper input:focus,
.input-wrapper textarea:focus,
.input-wrapper select:focus {
  outline: none;
  background: #ebebeb;
  border-color: #303030;
}

.input-wrapper--invalid label,
.input-wrapper--invalid p {
  color: red !important;
}

.input-wrapper--invalid input,
.input-wrapper--invalid textarea,
.input-wrapper--invalid select {
  border-color: red !important;
  background: #ffd1d1;
}
.ant-col .input-wrapper{
  
}
.ant-checkbox-inner{
  display: inline-block;
}
.input-wrapper label.checkbox-label{
  display: inline-block;
  margin-top: 0;
}
input[type=checkbox], input[type=radio]{
  display: inline-block;
  width: auto;
  min-height: auto;
  margin-right: 10px;
  padding-top: 2px;
}


/* dark form */
.dark-form .input-wrapper label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #9EA4BC;
}
.dark-form .input-wrapper input,
.dark-form .input-wrapper textarea,
.dark-form .input-wrapper select {
  border: 2px solid #323544;
  background: #323544;
  color: white;
}

.dark-form .input-wrapper input:focus,
.dark-form .input-wrapper textarea:focus,
.dark-form .input-wrapper select:focus {
  background: #424657;
  border-color: #303030;
}

.dark-form .input-wrapper--invalid input,
.dark-form .input-wrapper--invalid textarea,
.dark-form .input-wrapper--invalid select {
  border-color: rgb(255, 24, 82) !important;
  background: #323544;
}