.mixed-scoring-panel{
    position: relative;
    width: 100%;
    height: 60px;
    background: #eeeeee;
    color: #343a40;
    border: 2px solid #e2e2e2;
    border-radius: 5px;
    margin-bottom: 30px;
}
.mixed-scoring-panel .container{
    height: 100%;
}
.mixed-scoring-panel .flex-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}
.mixed-scoring-panel p{
    padding-top: 3px;
    margin: 0;
    display: inline-block;
}
.mixed-scoring-panel button{
    float: right;
}
.mixed-scoring-panel label{
    display: none;
}
.mixed-scoring-panel .form-control{
    background: transparent;
    background-color: transparent;
    width: 100px;
    margin: 0;
}
.mixed-scoring-panel .form-control input{
    padding: 7px;
    width: 100%;
    min-height: 5px;
}
.mixed-scoring-panel .form-control p{
    display: none;
}
.mixed-scoring-panel form{
    display: flex;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
}
.mixed-scoring-panel button{
    padding: 7px;
    margin-left: 5px;
}