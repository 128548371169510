.slide-item{
    margin-bottom: 20px;
    max-width: 320px;
}
.slide-item .slide-card{
    height: auto;
    border-width: 2px;
}
.slide-item .slide-card img{
    height: auto;
    /* padding: 10px; */
}
.slide-item .slide-card .card-body{
    /* height: 364px; */
    height: 370px;
    position: relative;
}
.slide-card button{
    margin-right: 5px;
}
.slide-item.selected .slide-card{
    border-color: #2196f3;
    border-width: 2px;
}
.slide-item .score-wrapper{
    display: flex;
    align-items: center;
    position: absolute;
    bottom: -34px;
    left: 0;
    right: 0;
    width: 100%;
}
.slide-item .score{
    background: #22d029;
    text-align: center;
    color: white;
    margin: 0 auto;
    display: inline-block;
    border-radius: 50%;
    width: 68px;
    height: 68px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-size: 24px;
}