.about-page section#hero-section.dark-section{
    margin-bottom: 40px;
}

.about-page h2{
    color: white;
}

#team-section{
    /* padding-top: 30px; */
}
.about-page p{
}
.about-page h5{
    padding-top: 30px;
}