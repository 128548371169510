.scenario-stats{
    margin-top: 50px;
    margin-bottom: 50px;
    /* border: 2px solid #eae8e8; */
}

.brder-bottom{
    border-bottom: 2px solid #eae8e8;
}
.brder-top{
    border-top: 2px solid #eae8e8;
}
.brder-left{
    border-left: 2px solid #eae8e8;
}
.brder-right{
    border-right: 2px solid #eae8e8;
}
.scenario-stats .each-stat{
    padding: 34px;
    height: 175px;
    background: white;
    border-left: 2px solid #eae8e8;
    border-top: 2px solid #eae8e8;
    border-bottom: 2px solid #eae8e8;
}
.scenario-stats .each-stat:last-of-type{
    border-right: 2px solid #eae8e8;
}
.each-stat.large-stat{
    height: 350px;
}
.scenario-stats .number{
    font-size: 30px;
    font-weight: 600;
    display: block;
}
.scenario-stats .label{
    margin-top: -7px;
    font-size: 22px;
    font-weight: 100;
    display: block;
}
.scenario-stats .label::first-letter{
    text-transform: capitalize;
}