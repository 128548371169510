.image-upload.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.image-upload__preview {
  width: 400px;
  height: 300px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.image-upload__preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.image-upload label{
  display: block;
  font-weight: bold;
}