.logo{
    font-weight: 900;
    font-size: 42px;
    background: rgb(15,231,224);
    background: -moz-linear-gradient(306deg, rgba(15,231,224,1) 0%, rgba(90,112,240,1) 100%);
    background: -webkit-linear-gradient(306deg, rgba(15,231,224,1) 0%, rgba(90,112,240,1) 100%);
    background: linear-gradient(306deg, rgba(15,231,224,1) 0%, rgba(90,112,240,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0fe7e0",endColorstr="#5a70f0",GradientType=1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.logo-large{
    font-size: 42px;
}
.logo-sm{
    font-size: 30px;
}