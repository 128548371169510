.auth-wrapper{
    background: white;
}
.auth-wrapper.invite-accept{
    max-width: 900px;
    margin: 75px auto;
    height: 450px;
    overflow: hidden;
    border: 2px solid #ddd;
    border-radius: 10px;
}
.auth-wrapper.invite-accept hr{
    display: none;
}
.auth-wrapper .header{
    padding: 30px 0;
}
.auth-wrapper .header .logo-wrapper{
    
}
.auth-wrapper .header .logo-wrapper a{
    color: black;
}
.auth-wrapper .header .logo-wrapper img{
    width: 32px;
    margin-top: -3px;
}
.auth-wrapper .header .logo-wrapper span{
    font-size: 15px;
    margin-left: 10px;
}

.auth-wrapper .left-side{
    padding: 0 60px;
}
.auth-wrapper.invite-accept .left-side{
    padding: 0 30px;
}
.invite-headline{
    font-family: 'Abril Fatface', cursive;
    font-size: 36px;
    margin-top: 50px;
    word-spacing: 6px;
}
.auth-wrapper.invite-accept .invite-headline{
    font-size: 30px;
    margin-top: 100px;
}
.invite-subheadline{
    font-size: 18px;
    /* margin-top: 15px; */
}
.auth-wrapper.invite-accept .invite-form-wrapper{
    margin-top: 75px;
}
.invite-form-wrapper input{
    max-width: 500px;
    border: 2px solid #ddd;
    padding: 12px 15px;
}
.invite-form-wrapper button{
    display: block;
    width: 100%;
    max-width: 500px;
    margin-bottom: 15px;
    padding: 14px 1rem;
    height: auto;
}
.invite-form-wrapper label{
    margin-top: 20px;
}
.invite-form-success-message{
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #d8ece5;
    border: 6px solid aquamarine;
    border-radius: 10px;
    font-size: 1.3rem;
    height: 350px;
    margin-bottom: 15px;
}