.form {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
  max-width: none;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26); */
  border: 1px solid #ddd;
  border-radius: 6px;
  background: white;
}
.form.modal-form{
  border: none;
  margin: 0;
  width: 100%;
  padding: 0;
  margin-top: -15px;
}
.form-title {
  padding: 10px 0 20px 0;
  border-bottom: 1px solid #ddd;
  margin-bottom: 30px;
}
label {
  margin-top: 30px;
}
.label-no-margin label{
  margin-top: 0;
}