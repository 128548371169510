.card.scenario-card-instructor{
  border: 2px solid rgb(236 240 242);
  border-radius: 7px;
  transition: 0.3s;
}
.card.scenario-card-instructor.selected{
  border-color: rgb(7, 210, 119);
}
.card.scenario-card-instructor:hover{
  border-color: #96b7c4;
}
.card.scenario-card-instructor.selected:hover{
  border-color: rgb(7, 175, 100);
}
.scenario-card-instructor .scenario-item {
  margin-bottom: 20px;
}
.scenario-card-instructor {
  box-shadow: none;
  align-items: center;
  position: relative;
  margin: 0;
  border-radius: 6px;
  padding: 20px 25px;
  overflow: hidden;
  background: white;
}
.scenario-card-instructor .card-body {
  padding: 0;
  display: flex;
  justify-content: space-between;
  /* padding-left: 10px; */
}
.scenario-card-instructor .card-body .stats{
  display: flex;
  font-size: 14px;
  width: 25%;
}
.scenario-card-instructor .card-body .btns{
  display: flex;
  font-size: 14px;
  /* width: 25%; */
  align-items: center;
}
.scenario-card-instructor .card-body .btns button{
  margin-left: 7px;
}
.scenario-card-instructor .card-body .each-stats{
  padding: 0 15px;
}
.scenario-card-instructor .card-body .stats span{
  display: block;
}
.scenario-card-instructor .card-body .stats .number{
 font-size: 25px;
}
.scenario-card-instructor .card-body .stats .label{
  font-size: 14px;
  margin-top: -10px;
  opacity: 0.7;
 }
.scenario-card-instructor .card.horizonal-card {
  flex-direction: row;
}
.scenario-card-instructor .card.horizonal-card img {
  width: 300px;
  border-radius: 4px;
}
.scenario-card-instructor .btn {
  margin-right: 10px;
}
.scenario-card-instructor .card-title{
    margin-bottom: 0;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
}
