.card.class-card-instructor {
  border: 2px solid rgb(236 240 242);
  border-radius: 7px;
  transition: 0.3s;
  margin-bottom: 14px;
}
.card.class-card-instructor.selected{
  border-color: rgb(7, 210, 119);
}
.card.class-card-instructor:hover{
  border-color: #96b7c4;
}
.card.class-card-instructor.selected:hover{
  border-color: rgb(7, 175, 100);
}
.card.class-card-instructor.not-selected{
  /* opacity: 0.6; */
  /* filter: blur(0.3px); */
}
.class-card-instructor .class-item {
  margin-bottom: 20px;
}
.class-card-instructor {
  box-shadow: none;
  align-items: center;
  position: relative;
  margin: 0;
  border-radius: 6px;
  padding: 20px 25px;
  overflow: hidden;
  background: white;
}
.class-card-instructor .card-body {
  padding: 0;
  display: flex;
  justify-content: space-between;
  /* padding-left: 10px; */
}
.class-card-instructor .card-body .stats {
  display: flex;
  font-size: 14px;
  width: 25%;
}
.class-card-instructor .card-body .btns {
  display: flex;
  font-size: 14px;
  /* width: 25%; */
  align-items: center;
}
.class-card-instructor .card-body .btns button{
  margin-left: 7px;
}
.class-card-instructor .card-body .each-stats {
  padding: 0 15px;
}
.class-card-instructor .card-body .stats span {
  display: block;
}
.class-card-instructor .card-body .stats .number {
  font-size: 25px;
}
.class-card-instructor .card-body .stats .label {
  font-size: 14px;
  margin-top: -10px;
  opacity: 0.7;
}
.class-card-instructor .card.horizonal-card {
  flex-direction: row;
}
.class-card-instructor .card.horizonal-card img {
  width: 300px;
  border-radius: 4px;
}
.class-card-instructor .btn {
  margin-right: 10px;
}
.class-card-instructor .card-title {
  margin-bottom: 0;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
}
