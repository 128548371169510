.button {
  font: inherit;
  padding: 0.7rem 1.5rem;
  border: 1px solid #000000;
  border-radius: 6px;
  background: #232323;
  color: white;
  cursor: pointer;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
  font-size: 1.2rem;
}

.button:focus {
  outline: none;
}

.button:hover,
.button:active {
  background: #454545;
  border-color: #000000;
}

.button--inverse {
  background: transparent;
  color: #000000;
}

.button--inverse:hover,
.button--inverse:active {
  color: white;
  background: #dbdbdb;
}

.button--danger {
  background: #830000;
  border-color: #830000;
}

.button--danger:hover,
.button--danger:active {
  background: #f34343;
  border-color: #f34343;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.button--small {
  font-size: 0.8rem;
}

.button--big {
  font-size: 1.5rem;
}

.btn-full-width{
  display: block;
  width: 100%;
}
.mt-30{
  margin-top: 30px;
}

.btn-aqua{
  background: #12E3E1;
  color: #1c1f2c;
  transition: .3s ease;
  font-weight: 600;
}

.btn-aqua:disabled{
  opacity: 1;
}

.btn-aqua:hover{
  background: #13a19f;
  color: black;
}