.detail-feedback{
    background: #f5f7f8;
    padding: 40px 0;
}
.detail-feedback__overview {
  text-align: center;
  border: 2px solid #ddd;
  padding: 40px;
  border-radius: 10px;
  background: white;
  margin-bottom: 50px;
}
.detail-feedback__overview .avatar {
}
.detail-feedback__overview .avatar img{
    width: 125px;
}
.detail-feedback__overview .score {
}
.detail-feedback__overview .score .number {
  font-size: 40px;
  font-weight: 800;
}
.detail-feedback__overview .score .desc {
    font-size: 18px;
}
.detail-feedback__overview .scroll-down-guide {
    margin-top: 30px;
}
.detail-feedback__overview .scroll-down-guide span {
  display: inline-block;
  width: 100%;
}
.detail-feedback__overview .scroll-down-guide .anticon {
    font-size: 30px;
    margin-top: 10px;
}
.detail-feedback__overview .scroll-down-guide .text {
}
