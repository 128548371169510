:root {
  --columns: 2;
  --slide-width: 420px;
  --bankContainer-width: calc(100% - (var(--slide-width) + 40px));
  --selectedContainer-width: calc(var(--slide-width) + 40px);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fbfbfb;
  background: #12141D;
  /* overflow-x: scroll; */
}
/* .task-list:last-child{
  background: cyan;
} */

/* width */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #23222b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #605f6f;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}
.btn-link:hover,
.btn-link:focus {
  text-decoration: none;
}
.row{
  margin-left: -10px;
  margin-right: -10px;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-left: 10px;
  padding-right: 10px;
}
.hide {
  display: none !important;
}
.margin-right-3 {
  margin-right: 3px;
}
p{
  word-break: break-word;
}
.text-center{
  text-align: center;
}
.no-margin{
  margin: 0;
}
.simple-table{
  /* border-radius: 5px; */
  /* overflow: hidden; */
}
.simple-table th{
  border: 1px solid #ddd;
  padding: 5px 10px; 
  background: rgb(49, 96, 129);
  color: white;
  font-weight: 100;
}
.simple-table td{
  border: 1px solid #ddd;
  padding: 5px 10px; 
}
.border-gray{
  border: 1px solid #ddd;
}
.ant-avatar-sm{
  line-height: 17px;
}
.ant-avatar-lg{
  line-height: 28px;
}
.ant-popover-buttons .ant-btn-dangerous{
  color: white;
  background: red;
}
.ant-popover-buttons .ant-btn{
  font-size: 18px;
  padding: 5px 20px;
  height: auto;
}
.ant-popover-message .ant-popover-message-title{
  font-size: 18px;
}
/* .ant-menu-item a{
  text-transform: capitalize;
} */
.ant-avatar{
  border: 1px solid rgb(152, 151, 151);
}
.ant-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 25%), 0 6px 16px 0 rgb(0 0 0 / 36%), 0 9px 28px 8px rgb(0 0 0 / 6%);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
}
.justify-center{
  justify-content: center;
}
.black{
  color: black;
}
.margin-top-25{
  margin-top: 25px;
}
.margin-top-50{
  margin-top: 50px;
}
.unset-cursor{
  cursor: unset !important;
}
.zerowidth{
  width: 0 !important;
}
.full-width{
  width: 100% !important;
}
/* .d-flex{
  display: flex;
}
.justify-content-center{
  justify-content: center;
} */
/* end of general */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.navbar {
  margin-bottom: 0;
  padding: 0;
  background-color: #2c2b35;
}
.navbar-text{
  margin-left: 3px;
}
.navbar .left-wrapper {
  /* grid-template-columns: repeat(3, 320px); */
  position: relative;
  top: 0;
  margin: 0;
  width: calc(var(--slide-width) + 40px);
  background: #3a3946;
  display: flex;
  padding: 0.5rem 24px;
  height: 56px;
  align-items: center;
  transition: all 0.25s ease-out;
}
.navbar .right-wrapper {
  background: #2f2e3b;
  position: relative;
  right: 0;
  top: 0;
  margin: 0;
  width: var(--selectedContainer-width);
  padding: 0.5rem 24px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.25s ease-out;
}
.filter-nav .feedback-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -6px;
}
.filter-nav .feedback-btn button {
  margin-left: 8px;
  border-radius: 50%;
  width: 38px;
  height: 38px;
}
.btn-disabled {
  cursor: not-allowed !important;
}
.navbar .right-wrapper span{
  color: white;
}
#core-loop.expand-bankContainer .navbar .left-wrapper{
  width: var(--bankContainer-width);
}
#core-loop.expand-selectedContainer .navbar .right-wrapper{
  width: var(--bankContainer-width);
}
.navbar .help {
  width: 20px;
  position: absolute;
  right: 14px;
  opacity: 0.2;
  cursor: pointer;
  
}
.navbar .deck-sort-wrapper{
  position: absolute;
  left: 15px;
}
.navbar .deck-sort-wrapper .btn-darky{
  font-size: 12px;
  padding: 10px 7px;
}
.navbar .deck-sort-wrapper .btn-darky .anticon + span{
  margin-left: 4px;
}
.navbar-dark .navbar-brand {
  font-weight: 900;
  font-size: 15px;
}

.bank-slides {
  padding-top: 80px;
  padding-bottom: 60px;
  margin: 0 auto;
}
.slide_col {
  flex: 0 0 350px;
  max-width: 350px;
}
.slide_col > .col {
  height: 100%;
}
.slide_col .title {
  text-align: center;
}
.slide-card {
  height: 320px;
}
.item.active-item{
  border: 2px solid #149cbb !important;
}
.item.red{
  border: 3px solid red;
}
#selectedContainer .item.active-item{
  border-color: #3bdbff !important;
}
.rejected-item .header, .rejected-item p, .rejected-item .title, .rejected-item .img-wrapper{
  filter: blur(0.4px) grayscale(1);
}
.rejected-item .item{
  border-color: #ddd;
}
.rejected-item .btn-colors{
  display: none;
}
.rejected-item .btn-gray-fill{
  display: none;
}
.rejected-item .slide .actions{
  /* justify-content: center; */
  background: #eaeaea;
  height: 53px;
}
.rejected-item .item:after{
  content: "Rejected";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(238 238 238 / 70%) !important;
  color: #363636;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  /* filter: blur(1px) grayscale(1); */
}
.slide {
  padding: 14px 20px;
  /* position: relative; */
  padding-top: 20px;
  width: 100%;
}
.slide.loading::after {
  transition: 0.2s;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10001;
  border-radius: 2px;
  background: rgb(175, 175, 175);
  background: -moz-linear-gradient(
    310deg,
    rgb(216 216 221) 0%,
    rgb(239 239 242) 100%
  );
  background: -webkit-linear-gradient(
    310deg,
    rgb(216 216 221) 0%,
    rgb(239 239 242) 100%
  );
  background: linear-gradient(
    310deg,
    rgb(216 216 221) 0%,
    rgb(239 239 242) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#afafaf",endColorstr="#e2e2e2",GradientType=1);
}
.slide.loaded::after {
  opacity: 0;
  z-index: -1;
}
.slide.data-slide {
  padding: 10px;
  padding-top: 20px;
  padding: 10px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  height: 400px;
  justify-content: space-between;
}
.slide .header {
  position: absolute;
  width: 100%;
  padding: 0px 10px;
  height: 40px;
  left: 0;
  top: 0;
  background: #efeff2;
  text-align: left;
  color: #4b4a5a;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  z-index: 0;
}
.slide .title {
  font-size: 17px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.slide.data-slide .title {
  margin-bottom: 1px;
}
.slide p {
  font-size: 15px;
  margin-bottom: 15px;
  white-space: pre-line;
}
.slide .banner {
  width: 90%;
  height: 100%;
  border-radius: 3px;
  border: 1px solid #ddd
}
.slide .img-wrapper {
  position: relative;
  max-height: 256px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  overflow: hidden;
  /* position: absolute;
  height: 236px;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: flex;
  align-items: center;
  width: 294px;
  bottom: 56px; */
  max-height: 300px;
  justify-content: center;
  padding-bottom: 12px;
}
.slide .image-description-btn{
  position: absolute;
  bottom: 0px;
  right: 8px;
  background: #4e4d5b;
  color: white;
  border: none;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  padding: 0;
  font-size: 17px;
}
.slide .image-description-btn:hover{
  position: absolute;
  /* bottom: -14px; */
  /* right: 11px; */
  background: #5a586e;
  color: white;
  border: none;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  padding: 0;
  font-size: 17px; 
}
.slide .ant-table-wrapper{
  /* position: absolute;
  bottom: 56px;
  width: 294px; */
  max-width: 400px;
  overflow-x: scroll;
}
.slide .image-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #838193;
  top: 0;
  border-radius: 3px;
  opacity: 0;
}
.slide .actions {
  position: absolute;
  z-index: 10000;
  width: 200px;
  bottom: 0;
  left: 0;
  padding: 10px;
  background: #f8f8f8;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top: 1px solid #ddd;
}
.slide .actions .theme{
  font-size: 12px;
  line-height: 26px;
  border: 1px solid #ddd;
  padding: 0 13px;
  border-radius: 4px;
}
.slide .btn-colors {
  background: white;
  color: black;
  border-color: #ddd;
  font-size: 13px;
  width: auto;
  text-transform: capitalize;
}
.slide .btn-colors .label {
  /* display: none; */
}
.slide .dropdown-menu {
  min-width: 75px;
  transform: translate3d(0px, -38px, 0px) !important;
}
.slide .dropdown-item {
  font-size: 13px;
  padding: 2px 7px;
  width: auto;
}
.slide .slide-type{
  position: absolute;
  width: 64px;
  /* border: 1px solid black; */
  left: calc(50% - 32px);
  text-align: center;
}
.slide .zoom-btn{
  display: flex;
  height: 27px;
  align-items: center;
  border-radius: 50%;
  width: 27px;
  justify-content: center;
}
.dropup .dropdown-toggle::after {
  display: none;
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: black;
  background-color: white;
  border-color: #ddd;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}
select {
  padding: 5px;
  border: 1px solid #ddd;
  background: #f4f4f4;
  border-radius: 3px;
}
.disregard {
  width: 20px;
  margin-left: 10px;
  display: none;
}
.draggable-slide {
  margin-bottom: 15px;
}
.task-list {
  background: #f0f0f0;
  border-radius: 10px;
  padding: 7px;
  height: 100%;
}
/* selected col */
.selected_col .task-list {
  background: #2c2b35;
}
.selected_col .slide-card {
  border: 1px solid black;
}
#bankContainer {
  grid-template-columns: repeat(var(--columns), var(--slide-width));
  position: relative;
  top: 100px;
  margin: 0;
  height: calc(100vh - 44px);
  overflow-y: scroll;
  background: #fbfbfb;
  justify-content: center;
  opacity: 0;
  width: calc(var(--slide-width) + 40px);
  transition: width 0.5s ease-out;
  border-radius: 0;
  /* transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s; */
}
#core-loop.expand-bankContainer #bankContainer{
  width: calc(100% - var(--selectedContainer-width));
}
/* #coreloop.notexpand-bankContainer #bankContainer{
  width: 360px;
} */

.fadeIn {
  opacity: 1 !important;
  transition: opacity 0.2s 0s;
}
#bankContainer::-webkit-scrollbar {
  width: 0;
}
#selectedContainer {
  background: #4b4a5a;
  position: fixed;
  right: 0;
  top: 100px;
  border-radius: 0;
  margin: 0;
  grid-template-columns: repeat(var(--columns), var(--slide-width));
  height: calc(100vh - 144px);
  overflow-y: scroll;
  justify-content: center;
  width: var(--selectedContainer-width);
  transition: all 0.25s ease-out;
}
#core-loop.expand-selectedContainer #selectedContainer{
  width: var(--bankContainer-width);
  grid-template-columns: repeat(2, var(--slide-width));
}
#core-loop.expand-selectedContainer #selectedContainer .separator-li{
  display: none;
}
#core-loop.expand-selectedContainer .selected-submit-btn{
  width: 100%;
  margin: 0 26px;
}
.expandedContainer{
  width: var(--bankContainer-width);
}
.notExpandedContainer{
  width: 360px;
}
.accordion {
}
.accordion__card {
  border: none;
  border-bottom: 1px solid black !important;
}
.accordion__card-body {
  padding: 0;
  background: #2c2b35;
  min-height: 250px;
  color: white;
}
.accordion__card-body .slide-card {
  color: black;
}
.accordion__card-header {
  background: #3e3c49;
  padding: 0;
}
.accordion__card-header .btn {
  display: block;
  width: 100%;
  padding: 0.75rem 1.25rem;
  color: white;
}
.accordion .task-list {
  border-radius: 0;
}

/* core-section-types */
.core-section-types {
  width: 100%;
  left: 0;
  top: 56px;
  position: fixed;
  z-index: 1;
  display: flex;
}
.core-section-types .bank {
  margin: 0;
  height: 44px;
  width: calc(var(--slide-width) + 40px);
  background: #efeff2;
  justify-content: space-between;
  color: black;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #e4e4ea;
  padding: 0 24px;
  transition: all 0.25s ease-out;
}
#core-loop.expand-bankContainer .core-section-types .bank{
  width: var(--bankContainer-width);
}
.core-section-types .selected {
  background: #3a3946;
  margin: 0;
  width: var(--selectedContainer-width);
  height: 44px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.25s ease-out;
  padding: 0 20px;
}
#core-loop.expand-selectedContainer .core-section-types .selected{
  width: var(--bankContainer-width);
}
.core-section-types .bank .form-control {
  margin-left: 7px;
  width: 100px;
  font-size: 13px;
}
.selected-submit {
  position: fixed;
  bottom: 0;
  right: 0;
  width: calc(var(--slide-width) + 40px);
  height: 44px;
  background: #2f2e3a;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transition: width 0.25s ease-out;
}
#core-loop.expand-selectedContainer .selected-submit{
  width: var(--bankContainer-width);
}
.selected-submit-btn {
  background: #2261c8;
  border: none;
  width: 420px;
  height: 35px;
  color: white;
}
.selected-submit-btn a{
  color: white;
}
.selected-submit-btn:hover {
  background: rgb(11, 13, 24);
}
.selected-submit-btn:active{
  background: orange;
}
.selected-submit-btn.disabled{
  background: #5f5e6e;
  cursor: not-allowed;
}
/* form styles */
.form-control:focus {
  box-shadow: none;
}
#core-loop .form-control {
  padding: 0px 12px;
  height: 34px;
  font-size: 16px;
}
/* end of form styles */

@media screen and (max-width: 1380px) {
  #bankContainer {
    grid-template-columns: repeat(2, --slide-width);
  }
}
@media screen and (max-width: 1070px) {
  #bankContainer {
    grid-template-columns: repeat(1, --slide-width);
  }
}

.show-top {
  position: fixed;
  top: 100px;
  left: 100px;
  font-size: 40px;
  color: red;
  z-index: 99999;
}

.empty-slide {
  position: absolute;
  width: 90%;
  top: 22px;
  left: 5%;
  height: calc(100vh - 180px);
  border: 3px dashed #71707f;
  color: #b3b3c3;
  text-align: center;
  padding: 0;
  border-radius: 10px;
  overflow-y: scroll;
}
.empty-slide .title {
  padding: 20px;
  display: inline-block;
}
.shortcuts-wrapper {
  text-align: left;
}
.shortcuts-wrapper .each-shortcut {
  display: flex;
  font-size: 13px;
}
.shortcuts-wrapper .each-shortcut > div {
  width: 50%;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 5px 10px;
}
.shortcuts-wrapper .each-shortcut:nth-child(odd) {
  background: rgb(255 255 255 / 4%);
}
.shortcuts-wrapper .each-shortcut p{
  padding: 5px 10px;
}
.empty-slide table {
  background: none;
  font-size: 13px;
}
.empty-slide .table-dark {
  color: #b3b3c3;
}
.empty-slide .table-dark td {
  border: none;
}
.btn-details {
  color: #fff;
  background-color: #9190a0;
  border-color: #8c8b9b;
  font-size: 13px;
  padding: 3px 4px;
}
.btn-details:hover {
  color: #fff;
  background-color: #747386;
  border-color: #686777;
}
.btn-reject {
  color: #c72e2e;
  background-color: transparent;
  border-color: #ff5353;
  font-size: 13px;
  padding: 3px 4px;
  margin-right: 3px;
}
.btn-reject:hover {
  color: #c72e2e;
  background-color: transparent;
  border-color: #d83939;
}
.btn-gray-fill {
  color: #fff;
  background-color: #9190a0;
  border-color: #8c8b9b;
  font-size: 13px;
  padding: 3px 4px;
}
.btn-gray-fill:hover {
  color: #fff;
  background-color: #747386;
  border-color: #686777;
}
.btn-gray-border {
  color: #686777;
  background-color: transparent;
  border-color: #8c8b9b;
  font-size: 13px;
  padding: 3px 4px;
}
.btn-gray-border:hover {
  color: #686777;
  background-color: transparent;
  border-color: #686777;
}

.slide-select {
  font-size: 13px;
}
.slide .tags {
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
}
.circle.red {
  background: #ff4b3e;
}
.circle.purple {
  background: #9d66ff;
}
.circle.green {
  background: #5bc95f;
}
.circle.blue {
  background: #499ae1;
}
.circle.gray {
  background: #8e8e8e;
}

.label.red {
  color: #ff3a2c;
}
.label.purple {
  color: #7a3de4;
}
.label.green {
  color: #1cad21;
}
.label.blue {
  color: #2d87d4;
}
.label.gray {
  color: #8e8e8e;
}

.img-tag,
.circle {
  margin-right: 5px;
}

.img-tag {
  width: 20px;
}

.react-select {
  min-width: 150px;
  width: auto;
}
.sort-select{
  min-width: 140px;
  margin-left: 3px;
}
.background-item {
  border-color: #d5eef4;
}
.background-item .header {
  background: #e6faff;
}
.separator-li{
  /* height: 50px !important; */
}
.separator-li .slide{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 10px;
}
.separator-li .title{
  text-align: center;
  font-size: 17px;
  text-transform: capitalize;
}
.separator-li .header, .separator-li button, .separator-li .actions, .separator-li .text{
  display: none;
}
.separator-li .slidesCounter{
  position: absolute;
  top: 5px;
  right: 7px;
  background: white;
  border-radius: 50%;
  padding: 4px;
  font-size: 12px;
  border: 1px solid #8e8e8f;
}
.slide .btn-colors:focus{
  border-color: rgb(92, 121, 134);
}
.slidesCounter.error{
  color: white;
  background: #d93838;
  border-color: #bd1919;
}
.background-separator .slide{
  background: #e6faff;
}
.background-separator-item{
  border-color: #e6faff;
}
.data-separator .slide{
  background: #f5ebff;
}
.data-separator-item{
  border-color: #e7ddf2;
}
.insight-separator .slide{
  background: #e2fff8;
}
.insight-separator-item{
  border-color: #ddf2ea;
}
.data-item {
  border-color: #e7ddf2;
}
.data-item .header {
  background: #f5ebff;
}

.insight-item {
  border-color: #ddf2ea;
}
.insight-item .header {
  background: #e2fff8;
}

.slide-background {
}
.background-header {
  /* background: #e8f4ff !important; */
  border-top: 3px dashed black;
}

.data-header {
  /* background: #f4ebff !important; */
  border-top: 3px ridge black;
}

.insight-header {
  /* background: #ffffe1 !important; */
  border-top: 3px double black;
}

.drag-handle{
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  background: none;
  z-index: 1;
}
.active-item .drag-handle:focus-visible{
  outline: none !important;
}
.drag-handle svg{
  width: 18px;
}

#selectedContainer .reject-btn{
  display: none;
}
#selectedContainer .zoom-btn{
  /* display: none; */
}

/* expander */
.expander-wrapper{
  position: fixed;
  top: 50%;
  /* right: 345px; */
  z-index: 10;
  background: #1b1a22;
  border-radius: 50%;
  text-align: center;
  width: 25px;
  height: 25px;
  transition: all 0.25s ease-out;
}
.expander-wrapper img{
  width: 25px;
  padding: 7px;
  margin-left: -2px;
  margin-top: -2px;
  opacity: 0.7;
}
#core-loop.expand-selectedContainer .expander-wrapper{
  left: calc(var(--selectedContainer-width) - 10px);
  transform: rotate(-180deg);
}
#core-loop.expand-bankContainer .expander-wrapper{
  left: calc(100% - (var(--slide-width) + 40px + 13px));
}
/* end of expander */


.modal-backdrop.show{
  opacity: 0.7;
}
.slide .ant-table{
  line-height: 0;
}
.slide .ant-table th{
  line-height: 1;
}
.slide .ant-table td{
  line-height: initial;
}
.slide .ant-table *{
  border-color: #797b7c !important;
}
.slide .ant-table tr > th{
  background: #e8e8e8;
}
.slide .ant-table tr > td, .slide .ant-table tr > th{
  padding: 7px 0px;
  text-align: center;
  /* white-space: nowrap; */
}
.slide .ant-table-wrapper.scaled{
  transform: scale(1.12);
}
.slide .ant-table-wrapper.scaled .ant-table{
  border: none;
}
.slide .close-slide-icon{
  position: absolute;
  right: 22px;
  z-index: 1;
  font-size: 30px;
  top: 3px;
  line-height: 40px;
  display: none;
  cursor: pointer;
  transition: color 0.25s ease-out;
}
.slide .close-slide-icon:hover{
  color: rgb(0, 204, 231);
}
.item-wrapper.zoomed .close-slide-icon{
  display: block;
}
.circle-btn{
  border-radius: 50%;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-dark{
  background: #21212c;
}
.btn-dark.btn-disabled{
  background: #47474b;
  color: #8f8f96;
}
.btn-dark.btn-disabled span{
  color: #8f8f96;
}
.dropDown.light > button:hover, .dropDown.light > button:focus, .dropDown.light > button:active {
  color: #030303;
  background-color: #dcdcdc;
  border-color: #c1c1c1;
}
.dropDown.light > button{
  color: #030303;
  background-color: #f4f4f4;
  border-color: #e7e7e7;
}
.dropDown.dark > button:hover, .dropDown.dark > button:focus, .dropDown.dark > button:active {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
  text-transform: capitalize;
}
.dropDown.dark > button{
  color: #fff;
  background-color: #21212c;
  border-color: #12121f;
  text-transform: capitalize;
}
.btn-darky{
  background: #403f4e;
  border: none;
}
.btn-darky:hover{
  background: #565568;
  border: none;
}
.btn-darky:focus{
  background: #565568;
}
.circle-btn .anticon{
  vertical-align: 0;
}
.btn-icon, .dropDown > button{
  display: flex;
  justify-content: center;
  align-items: center;
}
.item-wrapper{
  transition: transform 0s ease-out;
}
.item-wrapper.zoomed{
  position: fixed;
  top: 25px;
  width: 90% !important;
  z-index: 10002;
  left: calc(5%);
  transition: transform 0.25s;
  height: auto !important;
}
.item-wrapper.zoomed > div:first-child{
  height: 95vh !important;
  width: 100%;
}
.item-wrapper.zoomed > div:first-child .slide {
  overflow: scroll;
  height: calc(95vh - 120px) !important;
  padding: 0px 24px;
  margin-top: 60px;
}
.item-wrapper.zoomed .slide p{
  font-size: 20px;
  display: inline-block;
  float: right;
  padding-left: 10px;    
  width: 40%;
}
.item-wrapper.zoomed .slide p.full-width{
  padding-left: 0px;    
  text-align: center;
  font-size: 26px;
}
.item-wrapper.zoomed .slide .img-wrapper{
  height: calc(100% - 90px) !important;
  max-height: none;
}

.item-wrapper.zoomed .slide .img-wrapper .image-description-btn{
  display: none;
}

.item-wrapper.zoomed .slide .title{
  font-size: 25px;
  text-align: center;
  margin-bottom: 15px !important;
}
.item-wrapper.zoomed .drag-handle{
  display: none;
}
.item-wrapper.zoomed .header{
  font-size: 20px;
  height: 60px;
  padding: 0 24px;
}
.item-wrapper.zoomed .slide .actions{
  padding: 13px 24px;
  height: 60px;
}
.item-wrapper.zoomed .slide .actions .theme{
  font-size: 20px;
}
.item-wrapper.zoomed .slide .actions button{
  font-size: 18px;
}
.item-wrapper.zoomed .slide .img-wrapper{
  justify-content: center;
  border: none;
}
.item-wrapper.zoomed .slide .banner{
  width: auto;
  border: 2px solid #ddd;
}
.item-wrapper.zoomed .slide .ant-table tr > td, .item-wrapper.zoomed .slide .ant-table tr > th{
  padding: 17px 0px;
  font-size: 17px;
}
.item-wrapper.zoomed .slide .ant-table-wrapper{
  /* position: absolute;
  bottom: 56px;
  width: 294px; */
  max-width: 100%;
  overflow-x: overflow;
}
.Item {
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: start;
  padding: 0;
  background-color: var(--background-color);
  box-shadow: var(--box-shadow);
  outline: none;
  border-radius: 10px;
  box-sizing: border-box;
  list-style: none;
  transform-origin: 50% 50%;
  -webkit-tap-highlight-color: transparent;
  color: var(--text-color);
  font-weight: var(--font-weight);
  font-size: 1rem;
  /* white-space: nowrap; */
  transform: scale(var(--scale, 1)) rotate(0deg);
  transition: var(--box-shadow) 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
  /* border: 2px solid #ddd; */
  border-width: 2px;
  border-style: solid;
  flex-direction: column;
  margin-bottom: 10px;
  /* transition: all 0.3s; */
  /* border-top: none; */
}
.slide-backdrop{
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgb(14 15 16 / 80%);
  z-index: 10001;
  transition: all 0.25s ease-out;
}
p.deck-feedback{
  padding: 40px;
  text-align: center;
  font-size: 20px;
  margin: 0;
  border: 2px solid #2196f3;
  border-radius: 10px;
  background: #eaf6ff;
}
.score-range-table{
  margin-top: 50px;
}
.ecampus-logo{
  position: absolute;
  text-align: center;
  bottom: 20px;
  width: 100%;
  left: 0;
}
.ecampus-logo img{
  height: 50px;
}
.ant-btn-sm{
  padding: 0px 14px;
}
.ant-modal-close-x{
  height: 56px;
  font-size: 18px;
  font-style: normal;
  line-height: 50px;
}
.feedback-dialog-message {
  margin-bottom: 20px;
  background: #dddddd2b;
  border: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 7px;
  font-size: 20px;
}
.disable-handle{
  z-index: -1 !important;
}
.ant-popover-message > .anticon{
  display: none;
}
.ant-popover-message-title{
  padding-left: 0;
}
.ant-table-thead > tr > th{
  text-transform: capitalize;
}
.ant-layout-footer.dark-footer{
  background: #07080c;
  color: rgb(106, 113, 122);
  padding: 40px 0;
  /* position: fixed; */
  /* bottom: 0; */
  /* width: 100%; */
  /* left: 0; */
  /* right: 0; */
  margin-top: 50px;
}



/* dark-section */
.dark-section{
  margin-bottom: 40px;
}

.dark-section .wrapper{
  border-radius: 20px;
  background-color: #1E212D;
  padding: 50px 60px;
  color: white;
}
.dark-section h1, .dark-section h2, .dark-section h3, .dark-section h4, .dark-section h5{
  color: white;
}
.dark-section p{
  color: white;
  opacity: 0.7;
  font-size: 1.2rem;
  font-weight: 400;
}
.dark-section a{
  color: white;
}
.card-btns{
  display: flex;
  justify-content: flex-end;
}
.flex-start{
  align-self: flex-start;
}
.margin-right-auto{
  margin-right: auto !important;
}
.btns.justify-content-end button{
  margin-left: 5px;
}

.btn-blue-fill {
  color: #fff;
  background-color: #2261c8;
  border-color: #2261c8;
  font-size: 13px;
  padding: 3px 4px;
}
.btn-select{
  padding-left: 10px;
  padding-right: 10px; 
}
.btn-blue-fill:hover {
  color: #fff;
  background-color: #2261c8;
  border-color: #2261c8;
}

.navbar .ant-dropdown-menu-title-content a:focus, .navbar span.ant-dropdown-menu-title-content:focus {
  background: rgb(61, 93, 222)!important;
}

.navbar .ant-dropdown-menu-title-content > a, .navbar span.ant-dropdown-menu-title-content{
  color: black;
}

.navbar .ant-dropdown-trigger:focus {
  background: rgb(61, 93, 222)!important;
}
.ant-dropdown-link{
  
}
.account-dropdown{
  padding: 4px 10px;
  font-size: 14px;
  height: 32px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  border: 1px solid #12121f;
}
.header-right-buttons{
  width: 200px;
  display: flex;
  justify-content: flex-end;
}