.home-page{
    /* min-height: calc(100vh - 200px); */
    /* background: #12141D; */
}
#hero-section {
    /* background-image: url('../images/purple-back.jpg'); */
    padding-bottom: 40px;
}
#hero-section .wrapper{
    background: #1E212D;
    border-radius: 20px;
    display: flex;
    /* min-height: calc(100vh - 240px); */
}
#hero-section .wrapper .left-side{
    padding: 80px 60px;
    width: 60%;
}
#hero-section .wrapper .right-side{
    padding: 80px 60px;
    width: 40%;
    background: #1c202c;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}
#hero-section .welcome-text{
    color: #fff;
    opacity: 0.8;
    font-size: 1.2rem;
}
#hero-section h1{
    font-size: 60px;
    font-weight: 800;
    max-width: 700px;
    background: rgb(15,231,224);
    background: -moz-linear-gradient(132deg, rgba(15,231,224,1) 0%, rgba(90,112,240,1) 43%, rgba(196,39,230,1) 100%);
    background: -webkit-linear-gradient(132deg, rgba(15,231,224,1) 0%, rgba(90,112,240,1) 43%, rgba(196,39,230,1) 100%);
    background: linear-gradient(132deg, rgba(15,231,224,1) 0%, rgba(90,112,240,1) 43%, rgba(196,39,230,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0fe7e0",endColorstr="#c427e6",GradientType=1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
#hero-section h1 small{
    font-size: 30px;
    font-weight: 400;
    max-width: 700px;
}
#hero-section .sub-headline{
    font-size: 1.4rem;
    font-weight: 400;
    max-width: 680px;
    padding-top: 20px;
    color: #9EA4BC;
    line-height: 1.8rem;
    margin-top: 30px;
}
#hero-section .sub-headline strong{
    color: #e1e4ed;
    font-weight: 400;
}
#hero-section h2.form-legend{
    color: white;
    opacity: 0.6;
    font-size: 1.5rem;
    margin-top: 45px;
}
#hero-section .form-note{
    color: white;
    font-size: 1.0rem;
    opacity: 0.3;
    margin-top: -20px;
    text-align: center;
}