.feedback-item{
    padding: 75px 0;
    border-bottom: 2px solid rgb(236, 236, 236);
}
.feedback-item:first-of-type{
    padding-top: 55px;
}
.feedback-item:last-of-type{
    padding-bottom: 55px;
}
.feedback-item .slide-item{
    margin-left: auto;
    margin-right: auto;
}
.feedback-text .title-wrapper{
    display: flex;
    align-items: center;
}
.feedback-text .title-wrapper .info-icon{
    font-size: 40px;
    font-weight: 200;
    border: 1px solid black;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    text-align: center;
    line-height: 50px;
}
.feedback-text .title-wrapper .title{
    font-size: 20px;
    font-weight: 600;
}
.feedback-text .text{
    padding-top: 20px;
    font-size: 18px;
}
.feedback-score{
    text-align: center;
    display: block;
    width: 100%;
    padding: 15px 0;
}
.feedback-score span{
    padding: 15px 50px;
    background: black;
    color: white;
    border-radius: 5px;
}

.feedback-score.positive-score span{
    background: #53D989;
}
.feedback-score.negative-score span{
    background: #FF2358;
}

.feedback-score.individual-feedback{
    position: absolute;
    bottom: -27px;
    width: 100%;
    left: 0;
    right: 0;
}