@import url(https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-menu-item:first-child{
  margin-top: 0;
}
.ant-menu-item{
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  height: 80px !important;
}
.ant-layout{
  background: #f5f7f8;
}
body{
  margin: 0px;
}
.App {
  text-align: center;
}
a{
  color: black;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.section-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.section-header h1{
  font-size: 25px;
  margin: 0;
}
.section-header .sub-headline{
  font-size: 14px;
  margin-top: 7px;
  margin-bottom: 0;
  opacity: 0.7;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.capitalize{
  text-transform: capitalize;
}
.modal.show .modal-dialog{
  max-width: 800px;
}
.pad-b-10{
  padding-bottom: 10px;
}
.modal-footer{
  flex-direction: column;
}
.each-modal-feedback{
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  width: 100%;
  padding: 7px;
}
@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Button_Button__1AICQ {
  padding: 14px 20px;
  border: none;
  background-color: #242836;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  color: #f6f8ff;
  cursor: pointer;
  outline: none;
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: background 0.4s, -webkit-transform 0.2s;
  transition: transform 0.2s, background 0.4s;
  transition: transform 0.2s, background 0.4s, -webkit-transform 0.2s;

  &:hover,
  &:focus {
    background-color: #2f3545;
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
  }

  &:focus {
    box-shadow: 0 0 0 4px #4c9ffe;
  }

  &:active {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
}

.ConfirmModal_ConfirmModal__3qVgZ {
  --width: 250px;
  --height: 120px;

  display: flex;
  flex-direction: column;
  width: var(--width);
  height: var(--height);
  position: fixed;
  top: calc(100vh / 2 - var(--height) / 2);
  left: calc(100vw / 2 - var(--width) / 2);
  border-radius: 10px;
  box-shadow: -1px 0 15px 0 rgba(34, 33, 81, 0.01),
    0px 15px 15px 0 rgba(34, 33, 81, 0.25);
  padding: 15px;
  box-sizing: border-box;
  background-color: white;
  text-align: center;
  z-index: 1;

  h1 {
    flex: 1 1;
    font-size: 16px;
    font-weight: normal;
    line-height: 20px;
  }

  button {
    width: 50px;
    height: 23px;
    background: #242836;
    color: #f6f8ff;
    border: none;
    border-radius: 3px;
    margin: 0 5px;
    cursor: pointer;

    &:hover {
      background-color: #2f3545;
    }
  }
}

$focused-outline-color: #4c9ffe;

.Action_Action__227H- {
  display: flex;
  width: 12px;
  padding: 15px;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  touch-action: none;
  cursor: pointer;
  cursor: var(--cursor, pointer);
  border-radius: 5px;
  border: none;
  outline: none;
  -webkit-appearance: none;
          appearance: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;

  @media (hover: hover) {
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
      background-color: var(--action-background, rgba(0, 0, 0, 0.05));

      svg {
        fill: #6f7b88;
      }
    }
  }

  svg {
    flex: 0 0 auto;
    margin: auto;
    height: 100%;
    overflow: visible;
    fill: #919eab;
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.05);
    background-color: var(--background, rgba(0, 0, 0, 0.05));

    svg {
      fill: #788491;
      fill: var(--fill, #788491);
    }
  }

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0),
      0 0px 0px 2px $focused-outline-color;
  }
}

.Draggable_Draggable__1yQAr {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: -webkit-transform 250ms ease;
  transition: transform 250ms ease;
  transition: transform 250ms ease, -webkit-transform 250ms ease;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0);
          transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0);
}
.Draggable_Draggable__1yQAr button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  min-height: 54px;
  flex-shrink: 1;
  -webkit-appearance: none;
          appearance: none;
  outline: none;
  border: 0;
  padding: 8px 18px;
  background-color: #181a22;
  border-radius: 5px;
  box-shadow: var(--box-shadow);
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform: scale(var(--scale, 1));
          transform: scale(var(--scale, 1));
  transition: box-shadow 300ms ease, -webkit-transform 250ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
  transition: transform 250ms cubic-bezier(0.18, 0.67, 0.6, 1.22), box-shadow 300ms ease;
  transition: transform 250ms cubic-bezier(0.18, 0.67, 0.6, 1.22), box-shadow 300ms ease, -webkit-transform 250ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
}
.Draggable_Draggable__1yQAr:not(.Draggable_handle__2Vf42) button {
  touch-action: none;
  cursor: grab;
}
.Draggable_Draggable__1yQAr:not(.Draggable_handle__2Vf42) button:focus-visible:not(.Draggable_active__3sb8g .Draggable_Draggable__1yQAr:not(.Draggable_handle__2Vf42) button) {
  box-shadow: 0 0 0 3px #4c9ffe;
}
.Draggable_Draggable__1yQAr.Draggable_handle__2Vf42 button {
  --action-background: rgba(255, 255, 255, 0.1);
}
.Draggable_Draggable__1yQAr.Draggable_handle__2Vf42 button > svg {
  margin-right: 5px;
}
.Draggable_Draggable__1yQAr.Draggable_handle__2Vf42 button > button {
  margin-right: -10px;
}
.Draggable_Draggable__1yQAr img {
  width: 140px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
}
.Draggable_Draggable__1yQAr label {
  display: block;
  flex-shrink: 1;
  padding: 10px;
  transition: opacity 250ms ease;
  text-align: center;
  font-size: 1rem;
  font-weight: 300;
  color: #8d8d8d;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  /* cursor: url('/cursor.svg'), auto; */
  cursor: grab;
  -webkit-animation-name: Draggable_pulse__3voRp;
          animation-name: Draggable_pulse__3voRp;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
}
.Draggable_Draggable__1yQAr.Draggable_dragging__t1Hyi {
  z-index: 1;
  transition: none;
}
.Draggable_Draggable__1yQAr.Draggable_dragging__t1Hyi * {
  cursor: grabbing;
}
.Draggable_Draggable__1yQAr.Draggable_dragging__t1Hyi button {
  --scale: 1.06;
  --box-shadow: -1px 0 15px 0 rgba(34, 33, 81, 0.01), 0px 15px 15px 0 rgba(34, 33, 81, 0.25);
}
.Draggable_Draggable__1yQAr.Draggable_dragging__t1Hyi button:focus-visible {
  --box-shadow: 0 0px 10px 2px #4c9ffe;
}
.Draggable_Draggable__1yQAr.Draggable_dragging__t1Hyi label {
  -webkit-animation: none;
          animation: none;
  opacity: 0;
}
.Draggable_Draggable__1yQAr.Draggable_dragOverlay__EPT5L button {
  -webkit-animation: Draggable_pop__e9xEb 250ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
          animation: Draggable_pop__e9xEb 250ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
}
@-webkit-keyframes Draggable_pulse__3voRp {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes Draggable_pulse__3voRp {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes Draggable_pop__e9xEb {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(var(--scale));
            transform: scale(var(--scale));
    box-shadow: var(--box-shadow);
  }
}
@keyframes Draggable_pop__e9xEb {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(var(--scale));
            transform: scale(var(--scale));
    box-shadow: var(--box-shadow);
  }
}

.Droppable_Droppable__3jrVK {
  position: relative;
  padding-top: 80px;
  text-align: center;
  border-radius: 10px;
  width: 340px;
  height: 340px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: inset rgba(201, 211, 219, 0.5) 0 0 0 2px,
    rgba(255, 255, 255, 0) 0 0 0 1px, rgba(201, 211, 219, 0.25) 20px 14px 24px;
  transition: box-shadow 250ms ease;

  > svg {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 200px;
    -webkit-transform: translate3d(-50%, -50%, 0);
            transform: translate3d(-50%, -50%, 0);
    opacity: 0.8;
    transition: opacity 300ms ease, -webkit-transform 200ms ease;
    transition: opacity 300ms ease, transform 200ms ease;
    transition: opacity 300ms ease, transform 200ms ease, -webkit-transform 200ms ease;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    pointer-events: none;
  }

  &.Droppable_dragging__2c-yZ {
    > svg {
      opacity: 0.8;
    }
  }

  &.Droppable_over__OZ_dA {
    box-shadow: inset #1eb99d 0 0 0 3px, rgba(201, 211, 219, 0.5) 20px 14px 24px;

    > svg {
      opacity: 1;
    }

    &.Droppable_dropped__i49CD {
      box-shadow: inset rgba(201, 211, 219, 0.7) 0 0 0 3px,
        rgba(201, 211, 219, 0.5) 20px 14px 24px;
    }
  }

  &.Droppable_dropped__i49CD {
    > svg {
      opacity: 0.2;
      -webkit-transform: translate3d(-50%, 100%, 0) scale(0.8);
              transform: translate3d(-50%, 100%, 0) scale(0.8);
    }
  }
}

:root {
  --font-weight: 400;
  --background-color: #fff;
  --border-color: #efefef;
  --text-color: #333;
  --handle-color: rgba(0, 0, 0, 0.25);
  --box-shadow-border: 0 0 0 calc(1px / var(--scale-x, 1))
    rgba(63, 63, 68, 0.05);
  --box-shadow-common: 0 1px calc(3px / var(--scale-x, 1)) 0
    rgba(34, 33, 81, 0.15);
  --box-shadow: var(box-shadow-border), var(box-shadow-common);
  --focused-outline-color: #4c9ffe;
}
@-webkit-keyframes Item_pop__sTjXh {
  0% {
    -webkit-transform: scale(1) rotate(0deg);
            transform: scale(1) rotate(0deg);
    box-shadow: var(box-shadow-border), var(box-shadow-common);
    box-shadow: var(--box-shadow);
  }
  100% {
    -webkit-transform: scale(var(--scale)) rotate(0deg);
            transform: scale(var(--scale)) rotate(0deg);
    box-shadow: var(--box-shadow-picked-up);
  }
}
@keyframes Item_pop__sTjXh {
  0% {
    -webkit-transform: scale(1) rotate(0deg);
            transform: scale(1) rotate(0deg);
    box-shadow: var(box-shadow-border), var(box-shadow-common);
    box-shadow: var(--box-shadow);
  }
  100% {
    -webkit-transform: scale(var(--scale)) rotate(0deg);
            transform: scale(var(--scale)) rotate(0deg);
    box-shadow: var(--box-shadow-picked-up);
  }
}
@-webkit-keyframes Item_fadeIn__1XZA3 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes Item_fadeIn__1XZA3 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.Item_Wrapper__1RkK3 {
  display: flex;
  box-sizing: border-box;
  -webkit-transform: translate3d(0, 0, 0)
    scaleX(1) scaleY(1);
          transform: translate3d(0, 0, 0)
    scaleX(1) scaleY(1);
  -webkit-transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0)
    scaleX(var(--scale-x, 1)) scaleY(var(--scale-y, 1));
          transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0)
    scaleX(var(--scale-x, 1)) scaleY(var(--scale-y, 1));
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  touch-action: manipulation;
}
.Item_Wrapper__1RkK3.Item_fadeIn__1XZA3 {
  -webkit-animation: Item_fadeIn__1XZA3 500ms ease;
          animation: Item_fadeIn__1XZA3 500ms ease;
}
.Item_Wrapper__1RkK3.Item_dragOverlay__1ZHyO {
  --scale: 1.03;
  --box-shadow: var(--box-shadow);
  --box-shadow-picked-up: var(--box-shadow-border),
    -1px 0 15px 0 rgba(34, 33, 81, 0.01), 0px 15px 15px 0 rgba(34, 33, 81, 0.25);
  z-index: 999;
}
.Item_Item__6-g2w {
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: start;
  padding: 0;
  background-color: #fff;
  background-color: var(--background-color);
  box-shadow: var(box-shadow-border), var(box-shadow-common);
  box-shadow: var(--box-shadow);
  outline: none;
  border-radius: 10px;
  box-sizing: border-box;
  list-style: none;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  -webkit-tap-highlight-color: transparent;
  color: #333;
  color: var(--text-color);
  font-weight: 400;
  font-weight: var(--font-weight);
  font-size: 1rem;
  /* white-space: nowrap; */
  -webkit-transform: scale(1) rotate(0deg);
          transform: scale(1) rotate(0deg);
  -webkit-transform: scale(var(--scale, 1)) rotate(0deg);
          transform: scale(var(--scale, 1)) rotate(0deg);
  transition: var(box-shadow-border), var(box-shadow-common) 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
  transition: var(--box-shadow) 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
  /* border: 2px solid #ddd; */
  border-width: 2px;
  border-style: solid;
  flex-direction: column;
  margin-bottom: 10px;
  /* transition: all 0.3s; */
  /* border-top: none; */
}
.Item_Item__6-g2w:focus-within{
  /* border: 2px solid purple; */
}
.Item_Item__6-g2w:focus-visible {
  box-shadow: 0 0px 4px 1px #4c9ffe, var(box-shadow-border), var(box-shadow-common);
  box-shadow: 0 0px 4px 1px var(--focused-outline-color), var(--box-shadow);
}
.Item_Item__6-g2w:not(.Item_withHandle__1vivd) {
  touch-action: none;
  -ms-user-select: none;
      user-select: none;
  -webkit-user-select: none;
  cursor: grab;
}
.Item_Item__6-g2w.Item_dragging__s72Ff:not(.Item_dragOverlay__1ZHyO) {
  opacity: 0.5;
  opacity: var(--dragging-opacity, 0.5);
  z-index: 0;
}
/* overlay item when dragging */
.Item_Item__6-g2w.Item_dragging__s72Ff:not(.Item_dragOverlay__1ZHyO):focus {
  box-shadow: var(box-shadow-border), var(box-shadow-common);
  box-shadow: var(--box-shadow);
  /* background: red; */
}
.Item_Item__6-g2w.Item_disabled__2cWle {
  color: #999;
  background-color: #f1f1f1;
  cursor: not-allowed;
}
.Item_Item__6-g2w.Item_disabled__2cWle:focus {
  box-shadow: 0 0px 4px 1px rgba(0, 0, 0, 0.1), var(box-shadow);
}
/* style item when is dragging */
.Item_Item__6-g2w.Item_dragOverlay__1ZHyO {
  cursor: inherit;
  /* box-shadow: 0 0px 6px 2px var(focused-outline-color;
  */
  -webkit-animation: Item_pop__sTjXh 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
          animation: Item_pop__sTjXh 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
  -webkit-transform: scale(var(--scale)) rotate(0deg);
          transform: scale(var(--scale)) rotate(0deg);
  box-shadow: var(--box-shadow-picked-up);
  opacity: 1;
  border-color: rgb(80, 220, 255);
  background: white;
}
.Item_Item__6-g2w.Item_color__1b7QG:before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  height: 100%;
  width: 3px;
  display: block;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: var(--color);
}
.Item_Item__6-g2w:hover .Item_Remove__3b_NZ {
  visibility: visible;
}
.Item_Remove__3b_NZ {
  visibility: hidden;
}
.Item_Actions__3rJ42 {
  display: flex;
  align-self: flex-start;
  margin-top: -12px;
  margin-left: auto;
  margin-bottom: -15px;
  margin-right: -10px;
}
.Item_Item__6-g2w p{
  white-space: normal;
}
/* when selected by tab */
.Item_Item__6-g2w:focus-visible{
  border-color: rgb(80, 220, 255);
  background: white;
  border-width: 2px;
}
.FloatingControls_FloatingControls__elWS1 {
  position: fixed;
  top: 25px;
  right: 25px;
}

.Grid_Grid__1h3dM {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: repeating-linear-gradient(
      0deg,
      transparent,
      transparent calc(var(--grid-size) - 1px),
      #ddd calc(var(--grid-size) - 1px),
      #ddd var(--grid-size)
    ),
    repeating-linear-gradient(
      -90deg,
      transparent,
      transparent calc(var(--grid-size) - 1px),
      #ddd calc(var(--grid-size) - 1px),
      #ddd var(--grid-size)
    );
  background-size: var(--grid-size) var(--grid-size);
  z-index: -1;
  pointer-events: none;
}

.Grid_RangeSlider__1Nk_p {
  position: fixed;
  right: 20px;
  bottom: 20px;
}

.GridContainer_GridContainer__11de7 {
  max-width: 800px;
  display: grid;
  grid-template-columns: repeat(var(--col-count), 1fr);
  grid-gap: 10px;
  padding: 20px;

  @media (max-width: 850px) {
    grid-template-columns: repeat(calc(var(--col-count) - 1), 1fr);
  }

  @media (max-width: 650px) {
    grid-template-columns: repeat(calc(var(--col-count) - 2), 1fr);
  }
}

.List_List__1oUR8 {
  display: grid;
  grid-auto-rows: -webkit-max-content;
  grid-auto-rows: max-content;
  box-sizing: border-box;
  min-width: 350px;
  grid-gap: 0 10px;
  padding: 20px;
  margin: 10px;
  border-radius: 5px;
  min-height: 200px;
  transition: background-color 350ms ease;
  grid-template-columns: repeat(1, 1fr);
  grid-template-columns: repeat(var(--columns, 1), 1fr);

  &:after {
    content: '';
    height: 10px;
    grid-column-start: span 1;
    grid-column-start: span var(--columns, 1);
  }

  &.List_horizontal__2PRzV {
    width: 100%;
    grid-auto-flow: column;
  }
}

.OverflowWrapper_OverflowWrapper__1CcXG {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

.Wrapper_Wrapper__2CNpN {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  justify-content: flex-start;

  &.Wrapper_center__3obWn {
    justify-content: center;
  }
}

.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 3px solid #17181b;
  border-color: #17181b transparent #17181b transparent;
  -webkit-animation: lds-dual-ring 1.2s linear infinite;
          animation: lds-dual-ring 1.2s linear infinite;
}

.loading-spinner__overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner__overlay.panel-open{
  width: calc(100% - 201px);
}

@-webkit-keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.avatar {
  width: 100%;
  height: 100%;
  display: flex; 
  justify-content: center;
  align-items: center;
}

.avatar img {
  display: block;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.scenario-item{
  margin-bottom: 20px;
}
.card.scenario-card{
  box-shadow: none;
  align-items: center;
  position: relative;
  margin: 0;
  border-radius: 6px;
  padding: 1rem;
  overflow: hidden;
  background: white;
}
.card.scenario-card .card-body{
  padding: 0;
  padding-left: 20px;
}
.card.horizonal-card{
  flex-direction: row;
}
.card.horizonal-card img{
  width: 300px;
  border-radius: 4px;
}
.btn.btn__scenario-play{
  padding: 7px 24px;
}
.scenario-item .btn{
  margin-right: 10px;
}
.scenarios-list{
  width: 97%;
  max-width: 740px;
  margin: 0 auto;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10000;
}

.custom-modal {
  z-index: 10000;
  position: fixed;
  top: 22vh;
  left: 10%;
  width: 80%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
}

.modal__header {
  width: 100%;
  padding: 1rem 0.5rem;
  background: #223e60;
  color: white;
  text-align: center;
}

.modal__header h4{
  margin: 0.5rem;
  color: white;
}

.modal__content {
  padding: 20px 30px;
  padding-top: 70px;
  text-align: center;
}

.modal__content p{
  font-size: 19px;
}

.modal__footer {
  padding: 30px 30px;
  text-align: center;
}

@media (min-width: 768px) {
  .custom-modal {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}

.modal-enter {
  -webkit-transform: translateY(-10rem);
          transform: translateY(-10rem);
  opacity: 0;
}

.modal-enter-active {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.modal-exit {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  -webkit-transform: translateY(-10rem);
          transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}

.button {
  font: inherit;
  padding: 0.7rem 1.5rem;
  border: 1px solid #000000;
  border-radius: 6px;
  background: #232323;
  color: white;
  cursor: pointer;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
  font-size: 1.2rem;
}

.button:focus {
  outline: none;
}

.button:hover,
.button:active {
  background: #454545;
  border-color: #000000;
}

.button--inverse {
  background: transparent;
  color: #000000;
}

.button--inverse:hover,
.button--inverse:active {
  color: white;
  background: #dbdbdb;
}

.button--danger {
  background: #830000;
  border-color: #830000;
}

.button--danger:hover,
.button--danger:active {
  background: #f34343;
  border-color: #f34343;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.button--small {
  font-size: 0.8rem;
}

.button--big {
  font-size: 1.5rem;
}

.btn-full-width{
  display: block;
  width: 100%;
}
.mt-30{
  margin-top: 30px;
}

.btn-aqua{
  background: #12E3E1;
  color: #1c1f2c;
  transition: .3s ease;
  font-weight: 600;
}

.btn-aqua:disabled{
  opacity: 1;
}

.btn-aqua:hover{
  background: #13a19f;
  color: black;
}
.subtitle{
    margin-top:7px;
    font-size: 16px;
}
.scenarios-list{
  width: 100%;
  max-width: none;
  margin: 0 auto;
}
.card.scenario-card-instructor{
  border: 2px solid rgb(236 240 242);
  border-radius: 7px;
  transition: 0.3s;
}
.card.scenario-card-instructor.selected{
  border-color: rgb(7, 210, 119);
}
.card.scenario-card-instructor:hover{
  border-color: #96b7c4;
}
.card.scenario-card-instructor.selected:hover{
  border-color: rgb(7, 175, 100);
}
.scenario-card-instructor .scenario-item {
  margin-bottom: 20px;
}
.scenario-card-instructor {
  box-shadow: none;
  align-items: center;
  position: relative;
  margin: 0;
  border-radius: 6px;
  padding: 20px 25px;
  overflow: hidden;
  background: white;
}
.scenario-card-instructor .card-body {
  padding: 0;
  display: flex;
  justify-content: space-between;
  /* padding-left: 10px; */
}
.scenario-card-instructor .card-body .stats{
  display: flex;
  font-size: 14px;
  width: 25%;
}
.scenario-card-instructor .card-body .btns{
  display: flex;
  font-size: 14px;
  /* width: 25%; */
  align-items: center;
}
.scenario-card-instructor .card-body .btns button{
  margin-left: 7px;
}
.scenario-card-instructor .card-body .each-stats{
  padding: 0 15px;
}
.scenario-card-instructor .card-body .stats span{
  display: block;
}
.scenario-card-instructor .card-body .stats .number{
 font-size: 25px;
}
.scenario-card-instructor .card-body .stats .label{
  font-size: 14px;
  margin-top: -10px;
  opacity: 0.7;
 }
.scenario-card-instructor .card.horizonal-card {
  flex-direction: row;
}
.scenario-card-instructor .card.horizonal-card img {
  width: 300px;
  border-radius: 4px;
}
.scenario-card-instructor .btn {
  margin-right: 10px;
}
.scenario-card-instructor .card-title{
    margin-bottom: 0;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
}

.my-card {
  position: relative;
  margin: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  padding: 1rem;
  overflow: hidden;
  background: white;
}

.card.class-card-instructor {
  border: 2px solid rgb(236 240 242);
  border-radius: 7px;
  transition: 0.3s;
  margin-bottom: 14px;
}
.card.class-card-instructor.selected{
  border-color: rgb(7, 210, 119);
}
.card.class-card-instructor:hover{
  border-color: #96b7c4;
}
.card.class-card-instructor.selected:hover{
  border-color: rgb(7, 175, 100);
}
.card.class-card-instructor.not-selected{
  /* opacity: 0.6; */
  /* filter: blur(0.3px); */
}
.class-card-instructor .class-item {
  margin-bottom: 20px;
}
.class-card-instructor {
  box-shadow: none;
  align-items: center;
  position: relative;
  margin: 0;
  border-radius: 6px;
  padding: 20px 25px;
  overflow: hidden;
  background: white;
}
.class-card-instructor .card-body {
  padding: 0;
  display: flex;
  justify-content: space-between;
  /* padding-left: 10px; */
}
.class-card-instructor .card-body .stats {
  display: flex;
  font-size: 14px;
  width: 25%;
}
.class-card-instructor .card-body .btns {
  display: flex;
  font-size: 14px;
  /* width: 25%; */
  align-items: center;
}
.class-card-instructor .card-body .btns button{
  margin-left: 7px;
}
.class-card-instructor .card-body .each-stats {
  padding: 0 15px;
}
.class-card-instructor .card-body .stats span {
  display: block;
}
.class-card-instructor .card-body .stats .number {
  font-size: 25px;
}
.class-card-instructor .card-body .stats .label {
  font-size: 14px;
  margin-top: -10px;
  opacity: 0.7;
}
.class-card-instructor .card.horizonal-card {
  flex-direction: row;
}
.class-card-instructor .card.horizonal-card img {
  width: 300px;
  border-radius: 4px;
}
.class-card-instructor .btn {
  margin-right: 10px;
}
.class-card-instructor .card-title {
  margin-bottom: 0;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
}


.players-list .ant-table{
    border: 2px solid rgb(234, 232, 232);
}
.table-action{
    width: 150px;
}
.scenario-stats{
    margin-top: 50px;
    margin-bottom: 50px;
    /* border: 2px solid #eae8e8; */
}

.brder-bottom{
    border-bottom: 2px solid #eae8e8;
}
.brder-top{
    border-top: 2px solid #eae8e8;
}
.brder-left{
    border-left: 2px solid #eae8e8;
}
.brder-right{
    border-right: 2px solid #eae8e8;
}
.scenario-stats .each-stat{
    padding: 34px;
    height: 175px;
    background: white;
    border-left: 2px solid #eae8e8;
    border-top: 2px solid #eae8e8;
    border-bottom: 2px solid #eae8e8;
}
.scenario-stats .each-stat:last-of-type{
    border-right: 2px solid #eae8e8;
}
.each-stat.large-stat{
    height: 350px;
}
.scenario-stats .number{
    font-size: 30px;
    font-weight: 600;
    display: block;
}
.scenario-stats .label{
    margin-top: -7px;
    font-size: 22px;
    font-weight: 100;
    display: block;
}
.scenario-stats .label::first-letter{
    text-transform: capitalize;
}
.players-list .ant-table{
    border: 2px solid #cdd0d1;
}

.player-scenario-wrapper{
    padding: 40px 0;
    border-radius: 0;
    border-bottom: 3px solid #e4e7ea;
}
.player-scenario-wrapper:last-of-type{
    border-bottom: none;
}
.scenario-item__wrapper{
    border: 2px solid #cdd0d1;
    border-radius: 0;
    margin-bottom: 15px;
    background: white;
    border-radius: 3px;
}
.scenario-item__media{
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;
    position: relative;
}
.scenario-item__media .img-wrapper{
    padding: 25px;
    width: 440px;
}
.scenario-item__media .img-wrapper img{
    width: 100%;
    border-radius: 8px;
}
.scenario-item__media .body{
    width: calc(100% - 340px);
    padding: 25px;
    padding-left: 10px;
    position: relative;
    min-height: 200px;
}
.scenario-item__media .headline{
    font-size: 18px;
}
.scenario-item__media .sub-headline{
    font-size: 16px;
}
.scenario-item__media .description{
    font-size: 14px;
    min-height: 75px;
}
.scenario-item__wrapper .btns{
    display: flex;
    justify-content: flex-end;
    padding: 0;
    flex-wrap: wrap;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    padding-right: 20px;
    padding-bottom: 20px;
}
.scenario-item__wrapper .btns button{
    margin-left: 10px;
    margin-bottom: 7px;
}
.scenario-item__wrapper .btns button:first-of-type{
    margin-left: 0px;
}
.top-plays-wrapper{
    padding-top: 37px;
}
.detail-feedback{
    background: #f5f7f8;
    padding: 40px 0;
}
.detail-feedback__overview {
  text-align: center;
  border: 2px solid #ddd;
  padding: 40px;
  border-radius: 10px;
  background: white;
  margin-bottom: 50px;
}
.detail-feedback__overview .avatar {
}
.detail-feedback__overview .avatar img{
    width: 125px;
}
.detail-feedback__overview .score {
}
.detail-feedback__overview .score .number {
  font-size: 40px;
  font-weight: 800;
}
.detail-feedback__overview .score .desc {
    font-size: 18px;
}
.detail-feedback__overview .scroll-down-guide {
    margin-top: 30px;
}
.detail-feedback__overview .scroll-down-guide span {
  display: inline-block;
  width: 100%;
}
.detail-feedback__overview .scroll-down-guide .anticon {
    font-size: 30px;
    margin-top: 10px;
}
.detail-feedback__overview .scroll-down-guide .text {
}


.slide-item{
    margin-bottom: 20px;
    max-width: 320px;
}
.slide-item .slide-card{
    height: auto;
    border-width: 2px;
}
.slide-item .slide-card img{
    height: auto;
    /* padding: 10px; */
}
.slide-item .slide-card .card-body{
    /* height: 364px; */
    height: 370px;
    position: relative;
}
.slide-card button{
    margin-right: 5px;
}
.slide-item.selected .slide-card{
    border-color: #2196f3;
    border-width: 2px;
}
.slide-item .score-wrapper{
    display: flex;
    align-items: center;
    position: absolute;
    bottom: -34px;
    left: 0;
    right: 0;
    width: 100%;
}
.slide-item .score{
    background: #22d029;
    text-align: center;
    color: white;
    margin: 0 auto;
    display: inline-block;
    border-radius: 50%;
    width: 68px;
    height: 68px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-size: 24px;
}
.feedback-item{
    padding: 75px 0;
    border-bottom: 2px solid rgb(236, 236, 236);
}
.feedback-item:first-of-type{
    padding-top: 55px;
}
.feedback-item:last-of-type{
    padding-bottom: 55px;
}
.feedback-item .slide-item{
    margin-left: auto;
    margin-right: auto;
}
.feedback-text .title-wrapper{
    display: flex;
    align-items: center;
}
.feedback-text .title-wrapper .info-icon{
    font-size: 40px;
    font-weight: 200;
    border: 1px solid black;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    text-align: center;
    line-height: 50px;
}
.feedback-text .title-wrapper .title{
    font-size: 20px;
    font-weight: 600;
}
.feedback-text .text{
    padding-top: 20px;
    font-size: 18px;
}
.feedback-score{
    text-align: center;
    display: block;
    width: 100%;
    padding: 15px 0;
}
.feedback-score span{
    padding: 15px 50px;
    background: black;
    color: white;
    border-radius: 5px;
}

.feedback-score.positive-score span{
    background: #53D989;
}
.feedback-score.negative-score span{
    background: #FF2358;
}

.feedback-score.individual-feedback{
    position: absolute;
    bottom: -27px;
    width: 100%;
    left: 0;
    right: 0;
}
.input-wrapper {
  margin: 1rem 0;
  border: none;
  height: auto;
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
}

.input-wrapper label,
.input-wrapper input,
.input-wrapper textarea,
.input-wrapper select {
  display: block;
  font-size: 0.9rem;
}

.input-wrapper label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.input-wrapper input,
.input-wrapper textarea,
.input-wrapper select {
  width: 100%;
  font: inherit;
  border: 1px solid #ccc;
  background: #f8f8f8;
  padding: 10px;
  border-radius: 4px;
  min-height: 46px;
}

.input-wrapper input:focus,
.input-wrapper textarea:focus,
.input-wrapper select:focus {
  outline: none;
  background: #ebebeb;
  border-color: #303030;
}

.input-wrapper--invalid label,
.input-wrapper--invalid p {
  color: red !important;
}

.input-wrapper--invalid input,
.input-wrapper--invalid textarea,
.input-wrapper--invalid select {
  border-color: red !important;
  background: #ffd1d1;
}
.ant-col .input-wrapper{
  
}
.ant-checkbox-inner{
  display: inline-block;
}
.input-wrapper label.checkbox-label{
  display: inline-block;
  margin-top: 0;
}
input[type=checkbox], input[type=radio]{
  display: inline-block;
  width: auto;
  min-height: auto;
  margin-right: 10px;
  padding-top: 2px;
}


/* dark form */
.dark-form .input-wrapper label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #9EA4BC;
}
.dark-form .input-wrapper input,
.dark-form .input-wrapper textarea,
.dark-form .input-wrapper select {
  border: 2px solid #323544;
  background: #323544;
  color: white;
}

.dark-form .input-wrapper input:focus,
.dark-form .input-wrapper textarea:focus,
.dark-form .input-wrapper select:focus {
  background: #424657;
  border-color: #303030;
}

.dark-form .input-wrapper--invalid input,
.dark-form .input-wrapper--invalid textarea,
.dark-form .input-wrapper--invalid select {
  border-color: rgb(255, 24, 82) !important;
  background: #323544;
}
.form {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
  max-width: none;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26); */
  border: 1px solid #ddd;
  border-radius: 6px;
  background: white;
}
.form.modal-form{
  border: none;
  margin: 0;
  width: 100%;
  padding: 0;
  margin-top: -15px;
}
.form-title {
  padding: 10px 0 20px 0;
  border-bottom: 1px solid #ddd;
  margin-bottom: 30px;
}
label {
  margin-top: 30px;
}
.label-no-margin label{
  margin-top: 0;
}
.auth-wrapper{
    background: white;
}
.auth-wrapper.invite-accept{
    max-width: 900px;
    margin: 75px auto;
    height: 450px;
    overflow: hidden;
    border: 2px solid #ddd;
    border-radius: 10px;
}
.auth-wrapper.invite-accept hr{
    display: none;
}
.auth-wrapper .header{
    padding: 30px 0;
}
.auth-wrapper .header .logo-wrapper{
    
}
.auth-wrapper .header .logo-wrapper a{
    color: black;
}
.auth-wrapper .header .logo-wrapper img{
    width: 32px;
    margin-top: -3px;
}
.auth-wrapper .header .logo-wrapper span{
    font-size: 15px;
    margin-left: 10px;
}

.auth-wrapper .left-side{
    padding: 0 60px;
}
.auth-wrapper.invite-accept .left-side{
    padding: 0 30px;
}
.invite-headline{
    font-family: 'Abril Fatface', cursive;
    font-size: 36px;
    margin-top: 50px;
    word-spacing: 6px;
}
.auth-wrapper.invite-accept .invite-headline{
    font-size: 30px;
    margin-top: 100px;
}
.invite-subheadline{
    font-size: 18px;
    /* margin-top: 15px; */
}
.auth-wrapper.invite-accept .invite-form-wrapper{
    margin-top: 75px;
}
.invite-form-wrapper input{
    max-width: 500px;
    border: 2px solid #ddd;
    padding: 12px 15px;
}
.invite-form-wrapper button{
    display: block;
    width: 100%;
    max-width: 500px;
    margin-bottom: 15px;
    padding: 12px 15px;
    height: auto;
}
.invite-form-wrapper label{
    margin-top: 20px;
}
:root {
  --columns: 2;
  --slide-width: 420px;
  --bankContainer-width: calc(100% - (var(--slide-width) + 40px));
  --selectedContainer-width: calc(var(--slide-width) + 40px);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fbfbfb;
  background: #12141D;
  /* overflow-x: scroll; */
}
/* .task-list:last-child{
  background: cyan;
} */

/* width */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #23222b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #605f6f;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}
.btn-link:hover,
.btn-link:focus {
  text-decoration: none;
}
.row{
  margin-left: -10px;
  margin-right: -10px;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-left: 10px;
  padding-right: 10px;
}
.hide {
  display: none !important;
}
.margin-right-3 {
  margin-right: 3px;
}
p{
  word-break: break-word;
}
.text-center{
  text-align: center;
}
.no-margin{
  margin: 0;
}
.simple-table{
  /* border-radius: 5px; */
  /* overflow: hidden; */
}
.simple-table th{
  border: 1px solid #ddd;
  padding: 5px 10px; 
  background: rgb(49, 96, 129);
  color: white;
  font-weight: 100;
}
.simple-table td{
  border: 1px solid #ddd;
  padding: 5px 10px; 
}
.border-gray{
  border: 1px solid #ddd;
}
.ant-avatar-sm{
  line-height: 17px;
}
.ant-avatar-lg{
  line-height: 28px;
}
.ant-popover-buttons .ant-btn-dangerous{
  color: white;
  background: red;
}
.ant-popover-buttons .ant-btn{
  font-size: 18px;
  padding: 5px 20px;
  height: auto;
}
.ant-popover-message .ant-popover-message-title{
  font-size: 18px;
}
/* .ant-menu-item a{
  text-transform: capitalize;
} */
.ant-avatar{
  border: 1px solid rgb(152, 151, 151);
}
.ant-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 25%), 0 6px 16px 0 rgb(0 0 0 / 36%), 0 9px 28px 8px rgb(0 0 0 / 6%);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
}
.justify-center{
  justify-content: center;
}
.black{
  color: black;
}
.margin-top-25{
  margin-top: 25px;
}
.margin-top-50{
  margin-top: 50px;
}
.unset-cursor{
  cursor: unset !important;
}
.zerowidth{
  width: 0 !important;
}
.full-width{
  width: 100% !important;
}
/* .d-flex{
  display: flex;
}
.justify-content-center{
  justify-content: center;
} */
/* end of general */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.navbar {
  margin-bottom: 0;
  padding: 0;
  background-color: #2c2b35;
}
.navbar-text{
  margin-left: 3px;
}
.navbar .left-wrapper {
  /* grid-template-columns: repeat(3, 320px); */
  position: relative;
  top: 0;
  margin: 0;
  width: calc(420px + 40px);
  width: calc(var(--slide-width) + 40px);
  background: #3a3946;
  display: flex;
  padding: 0.5rem 24px;
  height: 56px;
  align-items: center;
  transition: all 0.25s ease-out;
}
.navbar .right-wrapper {
  background: #2f2e3b;
  position: relative;
  right: 0;
  top: 0;
  margin: 0;
  width: calc(420px + 40px);
  width: var(--selectedContainer-width);
  padding: 0.5rem 24px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.25s ease-out;
}
.filter-nav .feedback-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -6px;
}
.filter-nav .feedback-btn button {
  margin-left: 8px;
  border-radius: 50%;
  width: 38px;
  height: 38px;
}
.btn-disabled {
  cursor: not-allowed !important;
}
.navbar .right-wrapper span{
  color: white;
}
#core-loop.expand-bankContainer .navbar .left-wrapper{
  width: calc(100% - (420px + 40px));
  width: var(--bankContainer-width);
}
#core-loop.expand-selectedContainer .navbar .right-wrapper{
  width: calc(100% - (420px + 40px));
  width: var(--bankContainer-width);
}
.navbar .help {
  width: 20px;
  position: absolute;
  right: 14px;
  opacity: 0.2;
  cursor: pointer;
  
}
.navbar .deck-sort-wrapper{
  position: absolute;
  left: 15px;
}
.navbar .deck-sort-wrapper .btn-darky{
  font-size: 12px;
  padding: 10px 7px;
}
.navbar .deck-sort-wrapper .btn-darky .anticon + span{
  margin-left: 4px;
}
.navbar-dark .navbar-brand {
  font-weight: 900;
  font-size: 15px;
}

.bank-slides {
  padding-top: 80px;
  padding-bottom: 60px;
  margin: 0 auto;
}
.slide_col {
  flex: 0 0 350px;
  max-width: 350px;
}
.slide_col > .col {
  height: 100%;
}
.slide_col .title {
  text-align: center;
}
.slide-card {
  height: 320px;
}
.item.active-item{
  border: 2px solid #149cbb !important;
}
.item.red{
  border: 3px solid red;
}
#selectedContainer .item.active-item{
  border-color: #3bdbff !important;
}
.rejected-item .header, .rejected-item p, .rejected-item .title, .rejected-item .img-wrapper{
  -webkit-filter: blur(0.4px) grayscale(1);
          filter: blur(0.4px) grayscale(1);
}
.rejected-item .item{
  border-color: #ddd;
}
.rejected-item .btn-colors{
  display: none;
}
.rejected-item .btn-gray-fill{
  display: none;
}
.rejected-item .slide .actions{
  /* justify-content: center; */
  background: #eaeaea;
  height: 53px;
}
.rejected-item .item:after{
  content: "Rejected";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(238 238 238 / 70%) !important;
  color: #363636;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  /* filter: blur(1px) grayscale(1); */
}
.slide {
  padding: 14px 20px;
  /* position: relative; */
  padding-top: 20px;
  width: 100%;
}
.slide.loading::after {
  transition: 0.2s;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10001;
  border-radius: 2px;
  background: rgb(175, 175, 175);
  background: linear-gradient(
    310deg,
    rgb(216 216 221) 0%,
    rgb(239 239 242) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#afafaf",endColorstr="#e2e2e2",GradientType=1);
}
.slide.loaded::after {
  opacity: 0;
  z-index: -1;
}
.slide.data-slide {
  padding: 10px;
  padding-top: 20px;
  padding: 10px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  height: 400px;
  justify-content: space-between;
}
.slide .header {
  position: absolute;
  width: 100%;
  padding: 0px 10px;
  height: 40px;
  left: 0;
  top: 0;
  background: #efeff2;
  text-align: left;
  color: #4b4a5a;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  z-index: 0;
}
.slide .title {
  font-size: 17px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.slide.data-slide .title {
  margin-bottom: 1px;
}
.slide p {
  font-size: 15px;
  margin-bottom: 15px;
  white-space: pre-line;
}
.slide .banner {
  width: 90%;
  height: 100%;
  border-radius: 3px;
  border: 1px solid #ddd
}
.slide .img-wrapper {
  position: relative;
  max-height: 256px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  overflow: hidden;
  /* position: absolute;
  height: 236px;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: flex;
  align-items: center;
  width: 294px;
  bottom: 56px; */
  max-height: 300px;
  justify-content: center;
  padding-bottom: 12px;
}
.slide .image-description-btn{
  position: absolute;
  bottom: 0px;
  right: 8px;
  background: #4e4d5b;
  color: white;
  border: none;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  padding: 0;
  font-size: 17px;
}
.slide .image-description-btn:hover{
  position: absolute;
  /* bottom: -14px; */
  /* right: 11px; */
  background: #5a586e;
  color: white;
  border: none;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  padding: 0;
  font-size: 17px; 
}
.slide .ant-table-wrapper{
  /* position: absolute;
  bottom: 56px;
  width: 294px; */
  max-width: 400px;
  overflow-x: scroll;
}
.slide .image-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #838193;
  top: 0;
  border-radius: 3px;
  opacity: 0;
}
.slide .actions {
  position: absolute;
  z-index: 10000;
  width: 200px;
  bottom: 0;
  left: 0;
  padding: 10px;
  background: #f8f8f8;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top: 1px solid #ddd;
}
.slide .actions .theme{
  font-size: 12px;
  line-height: 26px;
  border: 1px solid #ddd;
  padding: 0 13px;
  border-radius: 4px;
}
.slide .btn-colors {
  background: white;
  color: black;
  border-color: #ddd;
  font-size: 13px;
  width: auto;
  text-transform: capitalize;
}
.slide .btn-colors .label {
  /* display: none; */
}
.slide .dropdown-menu {
  min-width: 75px;
  -webkit-transform: translate3d(0px, -38px, 0px) !important;
          transform: translate3d(0px, -38px, 0px) !important;
}
.slide .dropdown-item {
  font-size: 13px;
  padding: 2px 7px;
  width: auto;
}
.slide .slide-type{
  position: absolute;
  width: 64px;
  /* border: 1px solid black; */
  left: calc(50% - 32px);
  text-align: center;
}
.slide .zoom-btn{
  display: flex;
  height: 27px;
  align-items: center;
  border-radius: 50%;
  width: 27px;
  justify-content: center;
}
.dropup .dropdown-toggle::after {
  display: none;
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: black;
  background-color: white;
  border-color: #ddd;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}
select {
  padding: 5px;
  border: 1px solid #ddd;
  background: #f4f4f4;
  border-radius: 3px;
}
.disregard {
  width: 20px;
  margin-left: 10px;
  display: none;
}
.draggable-slide {
  margin-bottom: 15px;
}
.task-list {
  background: #f0f0f0;
  border-radius: 10px;
  padding: 7px;
  height: 100%;
}
/* selected col */
.selected_col .task-list {
  background: #2c2b35;
}
.selected_col .slide-card {
  border: 1px solid black;
}
#bankContainer {
  grid-template-columns: repeat(2, 420px);
  grid-template-columns: repeat(var(--columns), var(--slide-width));
  position: relative;
  top: 100px;
  margin: 0;
  height: calc(100vh - 44px);
  overflow-y: scroll;
  background: #fbfbfb;
  justify-content: center;
  opacity: 0;
  width: calc(420px + 40px);
  width: calc(var(--slide-width) + 40px);
  transition: width 0.5s ease-out;
  border-radius: 0;
  /* transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s; */
}
#core-loop.expand-bankContainer #bankContainer{
  width: calc(100% - calc(420px + 40px));
  width: calc(100% - var(--selectedContainer-width));
}
/* #coreloop.notexpand-bankContainer #bankContainer{
  width: 360px;
} */

.fadeIn {
  opacity: 1 !important;
  transition: opacity 0.2s 0s;
}
#bankContainer::-webkit-scrollbar {
  width: 0;
}
#selectedContainer {
  background: #4b4a5a;
  position: fixed;
  right: 0;
  top: 100px;
  border-radius: 0;
  margin: 0;
  grid-template-columns: repeat(2, 420px);
  grid-template-columns: repeat(var(--columns), var(--slide-width));
  height: calc(100vh - 144px);
  overflow-y: scroll;
  justify-content: center;
  width: calc(420px + 40px);
  width: var(--selectedContainer-width);
  transition: all 0.25s ease-out;
}
#core-loop.expand-selectedContainer #selectedContainer{
  width: calc(100% - (420px + 40px));
  width: var(--bankContainer-width);
  grid-template-columns: repeat(2, 420px);
  grid-template-columns: repeat(2, var(--slide-width));
}
#core-loop.expand-selectedContainer #selectedContainer .separator-li{
  display: none;
}
#core-loop.expand-selectedContainer .selected-submit-btn{
  width: 100%;
  margin: 0 26px;
}
.expandedContainer{
  width: calc(100% - (420px + 40px));
  width: var(--bankContainer-width);
}
.notExpandedContainer{
  width: 360px;
}
.accordion {
}
.accordion__card {
  border: none;
  border-bottom: 1px solid black !important;
}
.accordion__card-body {
  padding: 0;
  background: #2c2b35;
  min-height: 250px;
  color: white;
}
.accordion__card-body .slide-card {
  color: black;
}
.accordion__card-header {
  background: #3e3c49;
  padding: 0;
}
.accordion__card-header .btn {
  display: block;
  width: 100%;
  padding: 0.75rem 1.25rem;
  color: white;
}
.accordion .task-list {
  border-radius: 0;
}

/* core-section-types */
.core-section-types {
  width: 100%;
  left: 0;
  top: 56px;
  position: fixed;
  z-index: 1;
  display: flex;
}
.core-section-types .bank {
  margin: 0;
  height: 44px;
  width: calc(420px + 40px);
  width: calc(var(--slide-width) + 40px);
  background: #efeff2;
  justify-content: space-between;
  color: black;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #e4e4ea;
  padding: 0 24px;
  transition: all 0.25s ease-out;
}
#core-loop.expand-bankContainer .core-section-types .bank{
  width: calc(100% - (420px + 40px));
  width: var(--bankContainer-width);
}
.core-section-types .selected {
  background: #3a3946;
  margin: 0;
  width: calc(420px + 40px);
  width: var(--selectedContainer-width);
  height: 44px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.25s ease-out;
  padding: 0 20px;
}
#core-loop.expand-selectedContainer .core-section-types .selected{
  width: calc(100% - (420px + 40px));
  width: var(--bankContainer-width);
}
.core-section-types .bank .form-control {
  margin-left: 7px;
  width: 100px;
  font-size: 13px;
}
.selected-submit {
  position: fixed;
  bottom: 0;
  right: 0;
  width: calc(420px + 40px);
  width: calc(var(--slide-width) + 40px);
  height: 44px;
  background: #2f2e3a;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transition: width 0.25s ease-out;
}
#core-loop.expand-selectedContainer .selected-submit{
  width: calc(100% - (420px + 40px));
  width: var(--bankContainer-width);
}
.selected-submit-btn {
  background: #2261c8;
  border: none;
  width: 420px;
  height: 35px;
  color: white;
}
.selected-submit-btn a{
  color: white;
}
.selected-submit-btn:hover {
  background: rgb(11, 13, 24);
}
.selected-submit-btn:active{
  background: orange;
}
.selected-submit-btn.disabled{
  background: #5f5e6e;
  cursor: not-allowed;
}
/* form styles */
.form-control:focus {
  box-shadow: none;
}
#core-loop .form-control {
  padding: 0px 12px;
  height: 34px;
  font-size: 16px;
}
/* end of form styles */

@media screen and (max-width: 1380px) {
  #bankContainer {
    grid-template-columns: repeat(2, --slide-width);
  }
}
@media screen and (max-width: 1070px) {
  #bankContainer {
    grid-template-columns: repeat(1, --slide-width);
  }
}

.show-top {
  position: fixed;
  top: 100px;
  left: 100px;
  font-size: 40px;
  color: red;
  z-index: 99999;
}

.empty-slide {
  position: absolute;
  width: 90%;
  top: 22px;
  left: 5%;
  height: calc(100vh - 180px);
  border: 3px dashed #71707f;
  color: #b3b3c3;
  text-align: center;
  padding: 0;
  border-radius: 10px;
  overflow-y: scroll;
}
.empty-slide .title {
  padding: 20px;
  display: inline-block;
}
.shortcuts-wrapper {
  text-align: left;
}
.shortcuts-wrapper .each-shortcut {
  display: flex;
  font-size: 13px;
}
.shortcuts-wrapper .each-shortcut > div {
  width: 50%;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 5px 10px;
}
.shortcuts-wrapper .each-shortcut:nth-child(odd) {
  background: rgb(255 255 255 / 4%);
}
.shortcuts-wrapper .each-shortcut p{
  padding: 5px 10px;
}
.empty-slide table {
  background: none;
  font-size: 13px;
}
.empty-slide .table-dark {
  color: #b3b3c3;
}
.empty-slide .table-dark td {
  border: none;
}
.btn-details {
  color: #fff;
  background-color: #9190a0;
  border-color: #8c8b9b;
  font-size: 13px;
  padding: 3px 4px;
}
.btn-details:hover {
  color: #fff;
  background-color: #747386;
  border-color: #686777;
}
.btn-reject {
  color: #c72e2e;
  background-color: transparent;
  border-color: #ff5353;
  font-size: 13px;
  padding: 3px 4px;
  margin-right: 3px;
}
.btn-reject:hover {
  color: #c72e2e;
  background-color: transparent;
  border-color: #d83939;
}
.btn-gray-fill {
  color: #fff;
  background-color: #9190a0;
  border-color: #8c8b9b;
  font-size: 13px;
  padding: 3px 4px;
}
.btn-gray-fill:hover {
  color: #fff;
  background-color: #747386;
  border-color: #686777;
}
.btn-gray-border {
  color: #686777;
  background-color: transparent;
  border-color: #8c8b9b;
  font-size: 13px;
  padding: 3px 4px;
}
.btn-gray-border:hover {
  color: #686777;
  background-color: transparent;
  border-color: #686777;
}

.slide-select {
  font-size: 13px;
}
.slide .tags {
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
}
.circle.red {
  background: #ff4b3e;
}
.circle.purple {
  background: #9d66ff;
}
.circle.green {
  background: #5bc95f;
}
.circle.blue {
  background: #499ae1;
}
.circle.gray {
  background: #8e8e8e;
}

.label.red {
  color: #ff3a2c;
}
.label.purple {
  color: #7a3de4;
}
.label.green {
  color: #1cad21;
}
.label.blue {
  color: #2d87d4;
}
.label.gray {
  color: #8e8e8e;
}

.img-tag,
.circle {
  margin-right: 5px;
}

.img-tag {
  width: 20px;
}

.react-select {
  min-width: 150px;
  width: auto;
}
.sort-select{
  min-width: 140px;
  margin-left: 3px;
}
.background-item {
  border-color: #d5eef4;
}
.background-item .header {
  background: #e6faff;
}
.separator-li{
  /* height: 50px !important; */
}
.separator-li .slide{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 10px;
}
.separator-li .title{
  text-align: center;
  font-size: 17px;
  text-transform: capitalize;
}
.separator-li .header, .separator-li button, .separator-li .actions, .separator-li .text{
  display: none;
}
.separator-li .slidesCounter{
  position: absolute;
  top: 5px;
  right: 7px;
  background: white;
  border-radius: 50%;
  padding: 4px;
  font-size: 12px;
  border: 1px solid #8e8e8f;
}
.slide .btn-colors:focus{
  border-color: rgb(92, 121, 134);
}
.slidesCounter.error{
  color: white;
  background: #d93838;
  border-color: #bd1919;
}
.background-separator .slide{
  background: #e6faff;
}
.background-separator-item{
  border-color: #e6faff;
}
.data-separator .slide{
  background: #f5ebff;
}
.data-separator-item{
  border-color: #e7ddf2;
}
.insight-separator .slide{
  background: #e2fff8;
}
.insight-separator-item{
  border-color: #ddf2ea;
}
.data-item {
  border-color: #e7ddf2;
}
.data-item .header {
  background: #f5ebff;
}

.insight-item {
  border-color: #ddf2ea;
}
.insight-item .header {
  background: #e2fff8;
}

.slide-background {
}
.background-header {
  /* background: #e8f4ff !important; */
  border-top: 3px dashed black;
}

.data-header {
  /* background: #f4ebff !important; */
  border-top: 3px ridge black;
}

.insight-header {
  /* background: #ffffe1 !important; */
  border-top: 3px double black;
}

.drag-handle{
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  background: none;
  z-index: 1;
}
.active-item .drag-handle:focus-visible{
  outline: none !important;
}
.drag-handle svg{
  width: 18px;
}

#selectedContainer .reject-btn{
  display: none;
}
#selectedContainer .zoom-btn{
  /* display: none; */
}

/* expander */
.expander-wrapper{
  position: fixed;
  top: 50%;
  /* right: 345px; */
  z-index: 10;
  background: #1b1a22;
  border-radius: 50%;
  text-align: center;
  width: 25px;
  height: 25px;
  transition: all 0.25s ease-out;
}
.expander-wrapper img{
  width: 25px;
  padding: 7px;
  margin-left: -2px;
  margin-top: -2px;
  opacity: 0.7;
}
#core-loop.expand-selectedContainer .expander-wrapper{
  left: calc(calc(420px + 40px) - 10px);
  left: calc(var(--selectedContainer-width) - 10px);
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
#core-loop.expand-bankContainer .expander-wrapper{
  left: calc(100% - (420px + 40px + 13px));
  left: calc(100% - (var(--slide-width) + 40px + 13px));
}
/* end of expander */


.modal-backdrop.show{
  opacity: 0.7;
}
.slide .ant-table{
  line-height: 0;
}
.slide .ant-table th{
  line-height: 1;
}
.slide .ant-table td{
  line-height: normal;
  line-height: initial;
}
.slide .ant-table *{
  border-color: #797b7c !important;
}
.slide .ant-table tr > th{
  background: #e8e8e8;
}
.slide .ant-table tr > td, .slide .ant-table tr > th{
  padding: 7px 0px;
  text-align: center;
  /* white-space: nowrap; */
}
.slide .ant-table-wrapper.scaled{
  -webkit-transform: scale(1.12);
          transform: scale(1.12);
}
.slide .ant-table-wrapper.scaled .ant-table{
  border: none;
}
.slide .close-slide-icon{
  position: absolute;
  right: 22px;
  z-index: 1;
  font-size: 30px;
  top: 3px;
  line-height: 40px;
  display: none;
  cursor: pointer;
  transition: color 0.25s ease-out;
}
.slide .close-slide-icon:hover{
  color: rgb(0, 204, 231);
}
.item-wrapper.zoomed .close-slide-icon{
  display: block;
}
.circle-btn{
  border-radius: 50%;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-dark{
  background: #21212c;
}
.btn-dark.btn-disabled{
  background: #47474b;
  color: #8f8f96;
}
.btn-dark.btn-disabled span{
  color: #8f8f96;
}
.dropDown.light > button:hover, .dropDown.light > button:focus, .dropDown.light > button:active {
  color: #030303;
  background-color: #dcdcdc;
  border-color: #c1c1c1;
}
.dropDown.light > button{
  color: #030303;
  background-color: #f4f4f4;
  border-color: #e7e7e7;
}
.dropDown.dark > button:hover, .dropDown.dark > button:focus, .dropDown.dark > button:active {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
  text-transform: capitalize;
}
.dropDown.dark > button{
  color: #fff;
  background-color: #21212c;
  border-color: #12121f;
  text-transform: capitalize;
}
.btn-darky{
  background: #403f4e;
  border: none;
}
.btn-darky:hover{
  background: #565568;
  border: none;
}
.btn-darky:focus{
  background: #565568;
}
.circle-btn .anticon{
  vertical-align: 0;
}
.btn-icon, .dropDown > button{
  display: flex;
  justify-content: center;
  align-items: center;
}
.item-wrapper{
  transition: -webkit-transform 0s ease-out;
  transition: transform 0s ease-out;
  transition: transform 0s ease-out, -webkit-transform 0s ease-out;
}
.item-wrapper.zoomed{
  position: fixed;
  top: 25px;
  width: 90% !important;
  z-index: 10002;
  left: calc(5%);
  transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s;
  height: auto !important;
}
.item-wrapper.zoomed > div:first-child{
  height: 95vh !important;
  width: 100%;
}
.item-wrapper.zoomed > div:first-child .slide {
  overflow: scroll;
  height: calc(95vh - 120px) !important;
  padding: 0px 24px;
  margin-top: 60px;
}
.item-wrapper.zoomed .slide p{
  font-size: 20px;
  display: inline-block;
  float: right;
  padding-left: 10px;    
  width: 40%;
}
.item-wrapper.zoomed .slide p.full-width{
  padding-left: 0px;    
  text-align: center;
  font-size: 26px;
}
.item-wrapper.zoomed .slide .img-wrapper{
  height: calc(100% - 90px) !important;
  max-height: none;
}

.item-wrapper.zoomed .slide .img-wrapper .image-description-btn{
  display: none;
}

.item-wrapper.zoomed .slide .title{
  font-size: 25px;
  text-align: center;
  margin-bottom: 15px !important;
}
.item-wrapper.zoomed .drag-handle{
  display: none;
}
.item-wrapper.zoomed .header{
  font-size: 20px;
  height: 60px;
  padding: 0 24px;
}
.item-wrapper.zoomed .slide .actions{
  padding: 13px 24px;
  height: 60px;
}
.item-wrapper.zoomed .slide .actions .theme{
  font-size: 20px;
}
.item-wrapper.zoomed .slide .actions button{
  font-size: 18px;
}
.item-wrapper.zoomed .slide .img-wrapper{
  justify-content: center;
  border: none;
}
.item-wrapper.zoomed .slide .banner{
  width: auto;
  border: 2px solid #ddd;
}
.item-wrapper.zoomed .slide .ant-table tr > td, .item-wrapper.zoomed .slide .ant-table tr > th{
  padding: 17px 0px;
  font-size: 17px;
}
.item-wrapper.zoomed .slide .ant-table-wrapper{
  /* position: absolute;
  bottom: 56px;
  width: 294px; */
  max-width: 100%;
  overflow-x: overflow;
}
.Item {
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: start;
  padding: 0;
  background-color: var(--background-color);
  box-shadow: var(--box-shadow);
  outline: none;
  border-radius: 10px;
  box-sizing: border-box;
  list-style: none;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  -webkit-tap-highlight-color: transparent;
  color: var(--text-color);
  font-weight: var(--font-weight);
  font-size: 1rem;
  /* white-space: nowrap; */
  -webkit-transform: scale(1) rotate(0deg);
          transform: scale(1) rotate(0deg);
  -webkit-transform: scale(var(--scale, 1)) rotate(0deg);
          transform: scale(var(--scale, 1)) rotate(0deg);
  transition: var(--box-shadow) 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
  /* border: 2px solid #ddd; */
  border-width: 2px;
  border-style: solid;
  flex-direction: column;
  margin-bottom: 10px;
  /* transition: all 0.3s; */
  /* border-top: none; */
}
.slide-backdrop{
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgb(14 15 16 / 80%);
  z-index: 10001;
  transition: all 0.25s ease-out;
}
p.deck-feedback{
  padding: 40px;
  text-align: center;
  font-size: 20px;
  margin: 0;
  border: 2px solid #2196f3;
  border-radius: 10px;
  background: #eaf6ff;
}
.score-range-table{
  margin-top: 50px;
}
.ecampus-logo{
  position: absolute;
  text-align: center;
  bottom: 20px;
  width: 100%;
  left: 0;
}
.ecampus-logo img{
  height: 50px;
}
.ant-btn-sm{
  padding: 0px 14px;
}
.ant-modal-close-x{
  height: 56px;
  font-size: 18px;
  font-style: normal;
  line-height: 50px;
}
.feedback-dialog-message {
  margin-bottom: 20px;
  background: #dddddd2b;
  border: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 7px;
  font-size: 20px;
}
.disable-handle{
  z-index: -1 !important;
}
.ant-popover-message > .anticon{
  display: none;
}
.ant-popover-message-title{
  padding-left: 0;
}
.ant-table-thead > tr > th{
  text-transform: capitalize;
}
.ant-layout-footer.dark-footer{
  background: #07080c;
  color: rgb(106, 113, 122);
  padding: 40px 0;
  /* position: fixed; */
  /* bottom: 0; */
  /* width: 100%; */
  /* left: 0; */
  /* right: 0; */
  margin-top: 50px;
}



/* dark-section */
.dark-section{
  margin-bottom: 40px;
}

.dark-section .wrapper{
  border-radius: 20px;
  background-color: #1E212D;
  padding: 50px 60px;
  color: white;
}
.dark-section h1, .dark-section h2, .dark-section h3, .dark-section h4, .dark-section h5{
  color: white;
}
.dark-section p{
  color: white;
  opacity: 0.7;
  font-size: 1.2rem;
  font-weight: 400;
}
.dark-section a{
  color: white;
}
.card-btns{
  display: flex;
  justify-content: flex-end;
}
.flex-start{
  align-self: flex-start;
}
.margin-right-auto{
  margin-right: auto !important;
}
.btns.justify-content-end button{
  margin-left: 5px;
}

.btn-blue-fill {
  color: #fff;
  background-color: #2261c8;
  border-color: #2261c8;
  font-size: 13px;
  padding: 3px 4px;
}
.btn-select{
  padding-left: 10px;
  padding-right: 10px; 
}
.btn-blue-fill:hover {
  color: #fff;
  background-color: #2261c8;
  border-color: #2261c8;
}

.navbar .ant-dropdown-menu-title-content a:focus, .navbar span.ant-dropdown-menu-title-content:focus {
  background: rgb(61, 93, 222)!important;
}

.navbar .ant-dropdown-menu-title-content > a, .navbar span.ant-dropdown-menu-title-content{
  color: black;
}

.navbar .ant-dropdown-trigger:focus {
  background: rgb(61, 93, 222)!important;
}
.ant-dropdown-link{
  
}
.account-dropdown{
  padding: 4px 10px;
  font-size: 14px;
  height: 32px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  border: 1px solid #12121f;
}
.header-right-buttons{
  width: 200px;
  display: flex;
  justify-content: flex-end;
}
.user-item {
  margin: 1rem;
  width: calc(45% - 2rem);
  min-width: 17.5rem;
}

.user-item a {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  padding: 1rem;
  color: white;
  background: #292929;
}

.user-item a:hover,
.user-item a:active {
  background: #ffd900;
}

.user-item__content {
  padding: 0;
}

.user-item__image {
  width: 4rem;
  height: 4rem;
  margin-right: 1rem;
}

.user-item__info h2 {
  font-size: 1.5rem;
  margin: 0 0 0.5rem 0;
  font-weight: normal;
  color: #ffd900;
}

.user-item:hover h2,
.user-item:active h2,
.user-item:hover h3,
.user-item:active h3 {
  color: #292929;
}

.user-item__info h3 {
  margin: 0;
}
.users-list {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 90%;
  max-width: 50rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.logo{
    font-weight: 900;
    font-size: 42px;
    background: rgb(15,231,224);
    background: linear-gradient(306deg, rgba(15,231,224,1) 0%, rgba(90,112,240,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0fe7e0",endColorstr="#5a70f0",GradientType=1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.logo-large{
    font-size: 42px;
}
.logo-sm{
    font-size: 30px;
}
.main-header {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  /* position: fixed; */
  top: 0;
  left: 0;
  /* box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26); */
  padding: 0;
  z-index: 5;
  margin-bottom: 0;
  justify-content: space-between;
}

main {
  /* margin-top: 56px; */
}

@media (max-width: 768px) {
  .main-header {
    justify-content: space-between;
  }
}
.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-links li {
  margin: 1rem;
  display: inline-flex;
  margin: 0 1rem;
}

.nav-links a {
  text-decoration: none;
  background: transparent;
  color: #9EA4BC;
  padding: 10px 20px;
  font-size: 1.2rem;
}

.nav-links a:hover,
.nav-links a:active,
.nav-links a.active {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.nav-links button {
  cursor: pointer;
  border: 1px solid #e7e7e7;
  color: white;
  background: black;
  padding: 6px 15px;
  font-size: 16px;
}

.nav-links button:focus {
  outline: none;
}

.nav-links button:hover,
.nav-links button:active {
  background: #292929;
  color: black;
}

.nav-links .ant-avatar-image{
  width: 32px;
  height: 30px;
  margin-top: -3px;
  border-radius: 0;
  margin-right: 6px;
  border: none;
  border-right: 1px solid #ddd;
}
.nav-links .avatar-link{
  padding-left: 0;
}

@media (min-width: 768px) {
  .nav-links {
    flex-direction: row;
  }

  .nav-links li {
    margin: 0 1rem;
  }

  .nav-links button {
    background: #f4f4f4;
    border-color: #e7e7e7;
    color: #030303;
    padding: 3px 15px;
    font-size: 16px;
  }
  
  .nav-links button:hover,
  .nav-links button:active {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d;
  }
}

.side-drawer {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  height: 100vh;
  width: 70%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.main-navigation{
  background: #12141D;
}
.main-navigation__menu-btn {
  width: 3rem;
  height: 3rem;
  background: #12141D;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 2rem;
  cursor: pointer;
}

.main-navigation__menu-btn span {
  display: block;
  width: 3rem;
  height: 2.5px;
  background: white;
}

.main-navigation__title {
  color: black;
  font-size: 17px;
}

.main-navigation__title a {
  text-decoration: none;
  color: black;
}

.main-navigation__header-nav {
  display: none;
  margin-left: 50px;
}

.main-navigation__drawer-nav {
  height: 100%;
}

.main-navigation__logo-wrapper {
  display: flex;
  align-items: center;
}
.main-navigation__logo{
  height: 34px;
  margin-right: 10px;
}
.main-navigation__header-left-nav{
  display: flex;
}
@media (min-width: 768px) {
  .main-navigation__menu-btn {
    display: none;
  }

  .main-navigation__header-nav {
    display: block;
  }
}
.container{
    max-width: 1300px;
    padding-right: 14px;
    padding-left: 14px;
}
.image-upload.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.image-upload__preview {
  width: 400px;
  height: 300px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.image-upload__preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.image-upload label{
  display: block;
  font-weight: bold;
}
.form {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 90%;
  max-width: 60rem;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26); */
  border: 1px solid #ddd;
  border-radius: 6px;
  background: white;
}
.form-title{
  padding: 10px 0 20px 0;
  border-bottom: 1px solid #ddd;
  margin-bottom: 30px;
}
label{
  margin-top: 30px;
}
.label-no-margin label{
  margin-top: 0;
}


.each-combo{
    padding: 40px;
    border: 2px solid #ddd;
    margin-bottom: 50px;
    margin-top: 20px;
    border-radius: 10px;
    background: white;
}
.mixed-scoring-panel{
    position: relative;
    width: 100%;
    height: 60px;
    background: #eeeeee;
    color: #343a40;
    border: 2px solid #e2e2e2;
    border-radius: 5px;
    margin-bottom: 30px;
}
.mixed-scoring-panel .container{
    height: 100%;
}
.mixed-scoring-panel .flex-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}
.mixed-scoring-panel p{
    padding-top: 3px;
    margin: 0;
    display: inline-block;
}
.mixed-scoring-panel button{
    float: right;
}
.mixed-scoring-panel label{
    display: none;
}
.mixed-scoring-panel .form-control{
    background: transparent;
    background-color: transparent;
    width: 100px;
    margin: 0;
}
.mixed-scoring-panel .form-control input{
    padding: 7px;
    width: 100%;
    min-height: 5px;
}
.mixed-scoring-panel .form-control p{
    display: none;
}
.mixed-scoring-panel form{
    display: flex;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
}
.mixed-scoring-panel button{
    padding: 7px;
    margin-left: 5px;
}
#components-layout-demo-top-side-2 .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
    float: right;
    margin: 16px 0 16px 24px;
}

.site-layout-background {
    background: #fff;
}

.section-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 25px;
}
.section-header h1{
    font-size: 25px;
    font-weight: 300;
    margin: 0;
}
.section-header .sub-headline{
    font-size: 14px;
    margin-top: 7px;
    margin-bottom: 0;
    opacity: 0.7;
}
.section-header .btns button{
    margin-left: 7px;
}
.pad-20{
    padding: 20px;
}
.pad-40{
    padding: 40px;
}
.pad-60{
    padding: 60px;
}
.ant-btn{
    border-radius: 7px;
}
.ant-card-bordered{
    border-width: 2px;
}
.btn-black{
    background: black;
    color: white;
    border: none;
}
.btn-black:hover, .btn-black:focus{
    background: rgb(71, 71, 71);
    color: white;
}
.center{
    text-align: center;
}
.ant-breadcrumb-link{
    text-transform: capitalize;
}
.panel-header{
    margin-bottom: 10px;
}
.panel-header h5{
    display: inline-block;
}
.panel-header button{
    float: right;
    margin-top: 0px;
}
.scoreRange{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.scoreRange div{
    flex: 1 1;
    text-align: center;
    padding: 10px;
    border: 2px solid #fff;
}
.scoreRange div.selected{
    font-size: 20px;
    border: 4px solid #000;
    font-weight: bold;
}
.ant-menu-item a{
    text-transform: capitalize;
}

.home-page{
    /* min-height: calc(100vh - 200px); */
    /* background: #12141D; */
}
#hero-section {
    /* background-image: url('../images/purple-back.jpg'); */
    padding-bottom: 40px;
}
#hero-section .wrapper{
    background: #1E212D;
    border-radius: 20px;
    display: flex;
    /* min-height: calc(100vh - 240px); */
}
#hero-section .wrapper .left-side{
    padding: 80px 60px;
    width: 60%;
}
#hero-section .wrapper .right-side{
    padding: 80px 60px;
    width: 40%;
    background: #1c202c;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}
#hero-section .welcome-text{
    color: #fff;
    opacity: 0.8;
    font-size: 1.2rem;
}
#hero-section h1{
    font-size: 60px;
    font-weight: 800;
    max-width: 700px;
    background: rgb(15,231,224);
    background: linear-gradient(132deg, rgba(15,231,224,1) 0%, rgba(90,112,240,1) 43%, rgba(196,39,230,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0fe7e0",endColorstr="#c427e6",GradientType=1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
#hero-section h1 small{
    font-size: 30px;
    font-weight: 400;
    max-width: 700px;
}
#hero-section .sub-headline{
    font-size: 1.4rem;
    font-weight: 400;
    max-width: 680px;
    padding-top: 20px;
    color: #9EA4BC;
    line-height: 1.8rem;
    margin-top: 30px;
}
#hero-section .sub-headline strong{
    color: #e1e4ed;
    font-weight: 400;
}
#hero-section h2.form-legend{
    color: white;
    opacity: 0.6;
    font-size: 1.5rem;
    margin-top: 45px;
}
#hero-section .form-note{
    color: white;
    font-size: 1.0rem;
    opacity: 0.3;
    margin-top: -20px;
    text-align: center;
}
.auth-wrapper{
    background: white;
}
.auth-wrapper.invite-accept{
    max-width: 900px;
    margin: 75px auto;
    height: 450px;
    overflow: hidden;
    border: 2px solid #ddd;
    border-radius: 10px;
}
.auth-wrapper.invite-accept hr{
    display: none;
}
.auth-wrapper .header{
    padding: 30px 0;
}
.auth-wrapper .header .logo-wrapper{
    
}
.auth-wrapper .header .logo-wrapper a{
    color: black;
}
.auth-wrapper .header .logo-wrapper img{
    width: 32px;
    margin-top: -3px;
}
.auth-wrapper .header .logo-wrapper span{
    font-size: 15px;
    margin-left: 10px;
}

.auth-wrapper .left-side{
    padding: 0 60px;
}
.auth-wrapper.invite-accept .left-side{
    padding: 0 30px;
}
.invite-headline{
    font-family: 'Abril Fatface', cursive;
    font-size: 36px;
    margin-top: 50px;
    word-spacing: 6px;
}
.auth-wrapper.invite-accept .invite-headline{
    font-size: 30px;
    margin-top: 100px;
}
.invite-subheadline{
    font-size: 18px;
    /* margin-top: 15px; */
}
.auth-wrapper.invite-accept .invite-form-wrapper{
    margin-top: 75px;
}
.invite-form-wrapper input{
    max-width: 500px;
    border: 2px solid #ddd;
    padding: 12px 15px;
}
.invite-form-wrapper button{
    display: block;
    width: 100%;
    max-width: 500px;
    margin-bottom: 15px;
    padding: 14px 1rem;
    height: auto;
}
.invite-form-wrapper label{
    margin-top: 20px;
}
.invite-form-success-message{
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #d8ece5;
    border: 6px solid aquamarine;
    border-radius: 10px;
    font-size: 1.3rem;
    height: 350px;
    margin-bottom: 15px;
}
.about-page section#hero-section.dark-section{
    margin-bottom: 40px;
}

.about-page h2{
    color: white;
}

#team-section{
    /* padding-top: 30px; */
}
.about-page p{
}
.about-page h5{
    padding-top: 30px;
}
