.custom-modal {
  z-index: 10000;
  position: fixed;
  top: 22vh;
  left: 10%;
  width: 80%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
}

.modal__header {
  width: 100%;
  padding: 1rem 0.5rem;
  background: #223e60;
  color: white;
  text-align: center;
}

.modal__header h4{
  margin: 0.5rem;
  color: white;
}

.modal__content {
  padding: 20px 30px;
  padding-top: 70px;
  text-align: center;
}

.modal__content p{
  font-size: 19px;
}

.modal__footer {
  padding: 30px 30px;
  text-align: center;
}

@media (min-width: 768px) {
  .custom-modal {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}

.modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}
