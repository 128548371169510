#components-layout-demo-top-side-2 .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
    float: right;
    margin: 16px 0 16px 24px;
}

.site-layout-background {
    background: #fff;
}

.section-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 25px;
}
.section-header h1{
    font-size: 25px;
    font-weight: 300;
    margin: 0;
}
.section-header .sub-headline{
    font-size: 14px;
    margin-top: 7px;
    margin-bottom: 0;
    opacity: 0.7;
}
.section-header .btns button{
    margin-left: 7px;
}
.pad-20{
    padding: 20px;
}
.pad-40{
    padding: 40px;
}
.pad-60{
    padding: 60px;
}
.ant-btn{
    border-radius: 7px;
}
.ant-card-bordered{
    border-width: 2px;
}
.btn-black{
    background: black;
    color: white;
    border: none;
}
.btn-black:hover, .btn-black:focus{
    background: rgb(71, 71, 71);
    color: white;
}
.center{
    text-align: center;
}
.ant-breadcrumb-link{
    text-transform: capitalize;
}
.panel-header{
    margin-bottom: 10px;
}
.panel-header h5{
    display: inline-block;
}
.panel-header button{
    float: right;
    margin-top: 0px;
}
.scoreRange{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.scoreRange div{
    flex: 1;
    text-align: center;
    padding: 10px;
    border: 2px solid #fff;
}
.scoreRange div.selected{
    font-size: 20px;
    border: 4px solid #000;
    font-weight: bold;
}