.scenario-item{
  margin-bottom: 20px;
}
.card.scenario-card{
  box-shadow: none;
  align-items: center;
  position: relative;
  margin: 0;
  border-radius: 6px;
  padding: 1rem;
  overflow: hidden;
  background: white;
}
.card.scenario-card .card-body{
  padding: 0;
  padding-left: 20px;
}
.card.horizonal-card{
  flex-direction: row;
}
.card.horizonal-card img{
  width: 300px;
  border-radius: 4px;
}
.btn.btn__scenario-play{
  padding: 7px 24px;
}
.scenario-item .btn{
  margin-right: 10px;
}