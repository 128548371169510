:root {
  --font-weight: 400;
  --background-color: #fff;
  --border-color: #efefef;
  --text-color: #333;
  --handle-color: rgba(0, 0, 0, 0.25);
  --box-shadow-border: 0 0 0 calc(1px / var(--scale-x, 1))
    rgba(63, 63, 68, 0.05);
  --box-shadow-common: 0 1px calc(3px / var(--scale-x, 1)) 0
    rgba(34, 33, 81, 0.15);
  --box-shadow: var(box-shadow-border), var(box-shadow-common);
  --focused-outline-color: #4c9ffe;
}
@keyframes pop {
  0% {
    transform: scale(1) rotate(0deg);
    box-shadow: var(--box-shadow);
  }
  100% {
    transform: scale(var(--scale)) rotate(0deg);
    box-shadow: var(--box-shadow-picked-up);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.Wrapper {
  display: flex;
  box-sizing: border-box;
  transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0)
    scaleX(var(--scale-x, 1)) scaleY(var(--scale-y, 1));
  transform-origin: 0 0;
  touch-action: manipulation;
}
.Wrapper.fadeIn {
  animation: fadeIn 500ms ease;
}
.Wrapper.dragOverlay {
  --scale: 1.03;
  --box-shadow: var(--box-shadow);
  --box-shadow-picked-up: var(--box-shadow-border),
    -1px 0 15px 0 rgba(34, 33, 81, 0.01), 0px 15px 15px 0 rgba(34, 33, 81, 0.25);
  z-index: 999;
}
.Item {
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: start;
  padding: 0;
  background-color: var(--background-color);
  box-shadow: var(--box-shadow);
  outline: none;
  border-radius: 10px;
  box-sizing: border-box;
  list-style: none;
  transform-origin: 50% 50%;
  -webkit-tap-highlight-color: transparent;
  color: var(--text-color);
  font-weight: var(--font-weight);
  font-size: 1rem;
  /* white-space: nowrap; */
  transform: scale(var(--scale, 1)) rotate(0deg);
  transition: var(--box-shadow) 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
  /* border: 2px solid #ddd; */
  border-width: 2px;
  border-style: solid;
  flex-direction: column;
  margin-bottom: 10px;
  /* transition: all 0.3s; */
  /* border-top: none; */
}
.Item:focus-within{
  /* border: 2px solid purple; */
}
.Item:focus-visible {
  box-shadow: 0 0px 4px 1px var(--focused-outline-color), var(--box-shadow);
}
.Item:not(.withHandle) {
  touch-action: none;
  user-select: none;
  -webkit-user-select: none;
  cursor: grab;
}
.Item.dragging:not(.dragOverlay) {
  opacity: var(--dragging-opacity, 0.5);
  z-index: 0;
}
/* overlay item when dragging */
.Item.dragging:not(.dragOverlay):focus {
  box-shadow: var(--box-shadow);
  /* background: red; */
}
.Item.disabled {
  color: #999;
  background-color: #f1f1f1;
  cursor: not-allowed;
}
.Item.disabled:focus {
  box-shadow: 0 0px 4px 1px rgba(0, 0, 0, 0.1), var(box-shadow);
}
/* style item when is dragging */
.Item.dragOverlay {
  cursor: inherit;
  /* box-shadow: 0 0px 6px 2px var(focused-outline-color;
  */
  animation: pop 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
  transform: scale(var(--scale)) rotate(0deg);
  box-shadow: var(--box-shadow-picked-up);
  opacity: 1;
  border-color: rgb(80, 220, 255);
  background: white;
}
.Item.color:before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 100%;
  width: 3px;
  display: block;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: var(--color);
}
.Item:hover .Remove {
  visibility: visible;
}
.Remove {
  visibility: hidden;
}
.Actions {
  display: flex;
  align-self: flex-start;
  margin-top: -12px;
  margin-left: auto;
  margin-bottom: -15px;
  margin-right: -10px;
}
.Item p{
  white-space: normal;
}
/* when selected by tab */
.Item:focus-visible{
  border-color: rgb(80, 220, 255);
  background: white;
  border-width: 2px;
}