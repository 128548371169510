.main-header {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  /* position: fixed; */
  top: 0;
  left: 0;
  /* box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26); */
  padding: 0;
  z-index: 5;
  margin-bottom: 0;
  justify-content: space-between;
}

main {
  /* margin-top: 56px; */
}

@media (max-width: 768px) {
  .main-header {
    justify-content: space-between;
  }
}