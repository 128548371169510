@import '~antd/dist/antd.css';

.ant-menu-item:first-child{
  margin-top: 0;
}
.ant-menu-item{
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  height: 80px !important;
}
.ant-layout{
  background: #f5f7f8;
}
body{
  margin: 0px;
}
.App {
  text-align: center;
}
a{
  color: black;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.section-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.section-header h1{
  font-size: 25px;
  margin: 0;
}
.section-header .sub-headline{
  font-size: 14px;
  margin-top: 7px;
  margin-bottom: 0;
  opacity: 0.7;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.capitalize{
  text-transform: capitalize;
}
.modal.show .modal-dialog{
  max-width: 800px;
}
.pad-b-10{
  padding-bottom: 10px;
}
.modal-footer{
  flex-direction: column;
}
.each-modal-feedback{
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  width: 100%;
  padding: 7px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
